'use strict';

var debounce = require('lodash/debounce');
const utils = require('../utils');
var endpoint = $('.suggestions-wrapper').data('url');
var minChars = 3;
var UP_KEY = 38;
var DOWN_KEY = 40;
var DIRECTION_DOWN = 1;
var DIRECTION_UP = -1;

/**
 * capitalizeText
 * @param {string} string - string
 * @returns {string} String
 */
function capitalizeText(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    return $(scope).siblings('.suggestions-wrapper');
}

/**
 * Set Search value in Session Storage
 *
 * @param {string} searchTerm - Search input field DOM element
 */
function setSearchTermSessionValue(searchTerm) {
    var userTypedSearchTerm = window.sessionStorage.getItem('searchSuggest') ? window.sessionStorage.getItem('searchSuggest') : null;
    window.sessionStorage.setItem('userTypedSearchTerm', userTypedSearchTerm);
    var searchSuggest = searchTerm !== 'null' ? searchTerm : '';
    window.sessionStorage.setItem('searchTerm', searchTerm);
    window.sessionStorage.setItem('searchSuggest', searchSuggest);
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
    return !!$(scope).closest('.search-mobile').length;
}

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
    $('body').removeClass('modal-open');
    $('header').siblings().attr('aria-hidden', 'false');
    $('.suggestions').removeClass('modal');
}

/**
 * Apply modal classes needed for mobile suggestions
 *
 * @param {Object} scope - Search input field DOM element
 */
function applyModals(scope) {
    if (isMobileSearch(scope)) {
        $('body').addClass('modal-open');
        $('header').siblings().attr('aria-hidden', 'true');
        getSuggestionsWrapper(scope).find('.suggestions').addClass('modal');
    }
}

/**
 * Tear down Suggestions panel
 */
function tearDownSuggestions() {
    $('input.search-field').val('');
    clearModals();
    $('.search-mobile .suggestions').unbind('scroll');
    $('.suggestions-wrapper').empty();
}

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
function toggleSuggestionsIcon(action) {
    var mobileSearchIcon = '.search-mobile button.';
    var iconSearch = 'fa-search';
    var iconSearchClose = 'fa-close';

    if (action === 'close') {
        $(mobileSearchIcon + iconSearch).removeClass(iconSearch).addClass(iconSearchClose).attr('type', 'button');
    } else {
        $(mobileSearchIcon + iconSearchClose).removeClass(iconSearchClose).addClass(iconSearch).attr('type', 'submit');
    }
}

/**
 * Determines whether the "More Content Below" icon should be displayed
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function handleMoreContentBelowIcon(scope) {
    if (($(scope).scrollTop() + $(scope).innerHeight()) >= $(scope)[0].scrollHeight) {
        $('.more-below').fadeOut();
    } else {
        $('.more-below').fadeIn();
    }
}

/**
 * Positions Suggestions panel on page
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function positionSuggestions(scope) {
    var outerHeight;
    var $scope;
    var $suggestions;
    var top;

    if (isMobileSearch(scope)) {
        $scope = $(scope);
        top = $scope.offset().top;
        outerHeight = $scope.outerHeight();
        $suggestions = getSuggestionsWrapper(scope).find('.suggestions');
        $suggestions.css('top', top + outerHeight);

        handleMoreContentBelowIcon(scope);

        // Unfortunately, we have to bind this dynamically, as the live scroll event was not
        // properly detecting dynamic suggestions element's scroll event
        $suggestions.scroll(function () {
            handleMoreContentBelowIcon(this);
        });
    }
}

/**
 * update suggestion on mouseover
 *
 * @param {Object} scope - DOM element
 */
function getSuggestionOnHover(scope) {
    try {
        $.ajax({
            url: endpoint + encodeURIComponent(scope.innerText) + '&secondrySearch=true',
            method: 'GET',
            success: function (response) {
                let featuredInput = $('#suggestionText').val();
                $('.imagesdiv-class').html(response);
                $('#featuretopli .featurebestseller').html(featuredInput + ' ' + scope.innerText);
            }
        });
    } catch (error) {
        utils.showErrorToast(error);
    }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    // $('.spinner').hide();
    // $('.veil').hide();
    // $.spinner().stop();
    var $suggestionsWrapper = getSuggestionsWrapper(this).empty();

    // $.spinner().stop();

    if (response !== null) {
        $suggestionsWrapper.append(response).show();
        $(this).siblings('.reset-button');
        positionSuggestions(this);

        if (isMobileSearch(this)) {
            toggleSuggestionsIcon('close');
            applyModals(this);
        }

        // Trigger screen reader by setting aria-describedby with the new suggestion message.
        var suggestionsList = $('.suggestions .item');
        if ($(suggestionsList).length) {
            $('input.search-field').attr('aria-describedby', 'search-result-count');
        } else {
            $('input.search-field').removeAttr('aria-describedby');
        }

        var debounceHover = debounce(getSuggestionOnHover, 500);
        $('.sugcath-chld a, .sugcatg-item a, .popular-category-names a').on('mouseover', function (e) {
            debounceHover(this, e);
        });
    } else {
        $suggestionsWrapper.hide();
    }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val() !== undefined) {
        if ($(scope).val().length >= 1) {
            $('.emptysearch-btn').hide();
        } else {
            $('.emptysearch-btn').show();
            window.sessionStorage.removeItem('previousSearchTerm');
            window.sessionStorage.removeItem('lastSearchTerm');
        }

        if ($(scope).val().length >= minChars) {
            $('.reset-button').show();
        } else {
            $('.reset-button').hide();
        }
        setSearchTermSessionValue($(scope).val());
        if ($(scope).val().length < minChars) {
            // $.spinner().start();
            $.ajax({
                context: scope,
                url: endpoint + encodeURIComponent($(scope).val()),
                method: 'GET',
                success: processResponse,
                error: function () {
                    // $.spinner().stop();
                }
            });
        } else
            if ($(scope).val().length >= minChars) {
                // $.spinner().start();
                $.ajax({
                    context: scope,
                    url: endpoint + encodeURIComponent($(scope).val()),
                    method: 'GET',
                    success: processResponse,
                    error: function () {
                        // $.spinner().stop();
                    }
                });
            } else {
                toggleSuggestionsIcon('search');
                $(scope).siblings('.reset-button');
                clearModals();
                getSuggestionsWrapper(scope).empty();
            }
    }
}

/**
 * update search input value on up/down arrow keys
 *
 * @param {Object} $this - search input
 * @param {Object} $target - focused element
 */
function searchValueUpdate($this, $target) {
    let $searchText = $target.find('a')[0];
    if ($searchText) {
        if ($target.find('.ds-product-name').length) {
            $this.val($target.find('.ds-product-name span')[0].innerText);
        } else {
            $this.val($searchText.innerText);
            getSuggestionOnHover($searchText);
        }
        setSearchTermSessionValue($searchText.innerText);
    }
}

/**
 * Handle Search Suggestion Keyboard Arrow Keys
 *
 * @param {Integer} direction takes positive or negative number constant, DIRECTION_UP (-1) or DIRECTION_DOWN (+1)
 * @returns {boolean} boolean value
 */
// eslint-disable-next-line
function handleArrow(direction) {
    // get all li elements in the suggestions list
    var suggestionsList = $('.suggestions .item');

    if (direction === 1 && $(suggestionsList[suggestionsList.length - 1]).hasClass('selected')) {
        return true;
    }

    if (direction === -1 && ($(suggestionsList[0]).hasClass('selected') || $('input.search-field').val() === '')) {
        return true;
    }

    if (suggestionsList.filter('.selected').length === 0) {
        suggestionsList.first().addClass('selected');
        $('input.search-field').each(function () {
            $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
            searchValueUpdate($(this), $(suggestionsList.first()[0]));
        });
    } else {
        suggestionsList.each(function (index) {
            var idx = index + direction;
            if ($(this).hasClass('selected')) {
                $(this).removeClass('selected');
                $(this).removeAttr('aria-selected');
                if (suggestionsList.eq(idx).length !== 0) {
                    suggestionsList.eq(idx).addClass('selected');
                    suggestionsList.eq(idx).attr('aria-selected', true);
                    $(this).removeProp('aria-selected');
                    $('input.search-field').each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.eq(idx)[0].id);
                        searchValueUpdate($(this), $(suggestionsList.eq(idx)[0]));
                    });
                } else {
                    suggestionsList.first().addClass('selected');
                    suggestionsList.first().attr('aria-selected', true);
                    $('input.search-field').each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
                        searchValueUpdate($(this), $(suggestionsList.first()[0]));
                    });
                }
                return false;
            }
            return true;
        });
    }
}
/**
 * Handle Search Open
 */
function searchOpen() {
    if ($('.search-cont__inner-cont').has(event.target).length <= 0) {
        var overlayHtml = '<div class="searchoverlays"></div>';
        $(overlayHtml).prependTo('.search-cont ');
        const $searchDrawer = $('.search-cont');
        const $searchDrawerMask = $('.searchoverlays', $searchDrawer);
        const navBarClassList = document.querySelector('.ds-navbar-collapse').classList;
        $searchDrawer.find('.reset-button').attr('tabindex', '0');
        let searchTerm = window.sessionStorage.getItem('searchTerm');
        navBarClassList.remove('is-visible');
        navBarClassList.remove('is-dropdown-visible');
        $('.btn-close-icon').hide();
        $('.toggle-menu-mobile').show();
        $('.mega-wrapper').removeClass('show');
        $('.ds-globalnav-header').removeClass('drawer-open');
        if (searchTerm) {
            $('.search-cont .search-field').val(searchTerm);
        }
        setTimeout(() => {
            $('html')
                .addClass('hide-scroll-and-lock')
                .removeClass('nav-hover nav-hovering');
            $searchDrawerMask.addClass('show');
            $searchDrawer.addClass('open');
        }, 10);
    }
}
/**
 * Handle Search Close
 */
function searchclose() {
    var emailInputElement = document.getElementById('clrfunction');
    if (emailInputElement !== document.activeElement) {
        $('.search-close').attr('tabindex', 0).removeAttr('close');
        const $searchDrawer = $('.search-cont');
        const $searchDrawerMask = $('.searchoverlays', $searchDrawer);
        $searchDrawerMask.removeClass('show');
        $searchDrawer.find('.reset-button').attr('tabindex', '-1');
        $('body').removeClass('scroll-none');
        setTimeout(() => $searchDrawer.removeClass('open'), 10);
        setTimeout(() => {
            $('html').removeClass('hide-scroll-and-lock');
            $searchDrawerMask.remove();
        }, 800);
    }
}
/**
 * Handle Search Close on keypress
 */
function searchCloseOnKey() {
    searchclose();
    $('.quickbuy--desktop').show();
}

module.exports = function () {
    $(document).on('click', '.navbar-header .search', function (e) {
        const target = e.target.tagName.toLowerCase();
        var input = $('#clrfunction')[0];
        if (target && (target === 'a' || target === 'img')) {
            return;
        }
        searchOpen();
        $('.quickbuy--desktop').hide();

        const searchFocus = utils.getCurrentBreakpoint() === 'mobile';
        if (target === 'div' && $('.search-close').attr('close')) {
            searchCloseOnKey();
            return;
        }
        if (searchFocus) {
            getSuggestions($('.search-field'));
        } else {
            setTimeout(() => $('input.search-field').focus(), 800);
            if (target !== 'input' && target !== 'form') {
                input.selectionStart = input.selectionEnd = input.value.length;
            }
        }

        if ($('.search-field').val().length === 0) {
            return;
        }
        getSuggestions();
    });

    $(document).on('keyup', '.navbar-header .search', function (event) {
        if (event.code === 'Enter') {
            $(this).trigger('click');
        }
    });


    $(document).on('click', '.site-search .asset-close-icon ', function (event) {
        event.stopPropagation();
        searchCloseOnKey();
    });
    $(document).on('click', '.site-search', function (event) {
        if ($('.search-cont__inner-cont').has(event.target).length <= 0) {
            event.stopPropagation();
            searchCloseOnKey();
        }
    });

    $('form[name="simpleSearch"]').submit(function (e) {
        var suggestionsList = $('.suggestions .item');
        var userSearchInput = $('.search-field').val();
        var searchInputValue = $(this).find('#clrfunction').val();
        var regex = /[^\w\s]/gi;
        var encodedUserSearchInput = encodeURIComponent(userSearchInput);
        if (suggestionsList.filter('.selected').length !== 0) {
            e.preventDefault();
            suggestionsList.filter('.selected').find('a')[0].click();
        }
        e.preventDefault();
        var url = $(this).attr('action');
        var submitButtonName = $(this).find('.btn-searh-top').attr('name');
        var language = $(this).find('input[name=lang]').val();
        var searchTerm = window.sessionStorage.getItem('searchTerm') ? window.sessionStorage.getItem('searchTerm') : null;
        var lastSearchTerm = window.sessionStorage.getItem('lastSearchTerm');
        if (lastSearchTerm && regex.test(userSearchInput) === false) {
            window.sessionStorage.setItem('previousSearchTerm', lastSearchTerm);
        } else {
            window.sessionStorage.removeItem('previousSearchTerm');
            window.sessionStorage.removeItem('userTypedSearchTerm');
        }
        window.sessionStorage.setItem('lastSearchTerm', $('.search-field').val());
        var searchSuggest = window.sessionStorage.getItem('searchSuggest') ? window.sessionStorage.getItem('searchSuggest') : null;
        var userTypedSearchTerm = window.sessionStorage.getItem('userTypedSearchTerm') ? window.sessionStorage.getItem('userTypedSearchTerm') : null;
        var previousSearchTerm = window.sessionStorage.getItem('previousSearchTerm') ? window.sessionStorage.getItem('previousSearchTerm') : null;
        if (regex.test(userSearchInput) === true || userSearchInput === '') {
            e.preventDefault();
            searchTerm = encodedUserSearchInput;
            userTypedSearchTerm = encodedUserSearchInput;
            searchInputValue = encodedUserSearchInput;
            searchSuggest = encodedUserSearchInput;
            previousSearchTerm = encodedUserSearchInput;
        }
        if (regex.test(previousSearchTerm) === true) {
            previousSearchTerm = encodedUserSearchInput;
        }
        window.location.href = `${url}?${submitButtonName}=&searchRedirect=true&q=${searchInputValue}&lang=${language}&searchterm=${searchTerm}&searchsuggest=${searchSuggest}&usertypedsearch=${userTypedSearchTerm}&previousSearchTerm=${previousSearchTerm}`;
    });

    $('input.search-field').each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(getSuggestions, 300);
        $(this).on('keyup focus', function (e) {
            // Capture Down/Up Arrow Key Events
            switch (e.which) {
                case DOWN_KEY:
                    handleArrow(DIRECTION_DOWN);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                case UP_KEY:
                    handleArrow(DIRECTION_UP);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                default:
                    debounceSuggestions(this, e);
            }
        });
    });

    $('body').on('click touchend', '.search-mobile button.fa-close', function (e) {
        e.preventDefault();
        $('.suggestions').hide();
        toggleSuggestionsIcon('search');
        tearDownSuggestions();
    });

    $(document).on('click', 'button.reset-button', function () {
        $('#clrfunction')[0].defaultValue = '';
        setTimeout(() => {
            $('button.reset-button').hide();
            getSuggestions($('input#clrfunction'));
        }, 10);
    });

    $(document).on('focus', '.sugcath-chld a, .sugcatg-item a', function (e, data) {
        let self = $(this)[0];
        let context = this;
        var totalElement = $('.sugcath-chld a, .sugcatg-item a');
        var firstElement = totalElement[0];
        $('.featurebestseller').html('Top Results for ' + context.text);

        if ($(this).attr('tabindex') !== '-1' && !data) {
            $.ajax({
                url: endpoint + encodeURIComponent(self.innerText) + '&secondrySearch=true',
                method: 'GET',
                success: function (response) {
                    $('.imagesdiv-class').html($(response).html());
                    if ($(firstElement).attr('flag') === undefined) {
                        totalElement.not(context).attr('tabindex', '-1');
                        $(firstElement).attr('flag', '1');
                    }
                    $(context).trigger('focus', true);
                }
            });
        }
    });

    $(document).on('blur', '.sugcath-chld a, .sugcatg-item a', function () {
        $($('.imagesdiv-class a')[0]).trigger('focus');
    });

    $(document).on('blur', '.imagesdiv-class a[tabindex != -1]:last', function () {
        let focusableList = $('.srch_suggest .sugcath-chld a[tabindex], .srch_suggest .sugcatg-item a[tabindex]');
        if (focusableList.length) {
            $(focusableList[0]).removeAttr('tabindex').trigger('focus');
        } else {
            $('.search-close').attr('close', 'true').attr('tabindex', '1').trigger('focus');
        }
    });

    $(document).on('blur', '.search-close', function () {
        if ($('.search-cont').hasClass('open')) {
            $('.search-close').attr('close', 'true').trigger('focus');
        }
    });

    $(document).on('click', '.sugcath-chld a, .sugcatg-item a', function (e) {
        e.preventDefault();
        var suggestionName = capitalizeText($(this).data('suggestionname'));
        setSearchTermSessionValue(suggestionName);
        var url = $(this).data('url');
        var lastSearchTerm = window.sessionStorage.getItem('lastSearchTerm');
        if (lastSearchTerm) {
            window.sessionStorage.setItem('previousSearchTerm', lastSearchTerm);
        } else {
            window.sessionStorage.removeItem('previousSearchTerm');
        }
        window.sessionStorage.setItem('lastSearchTerm', suggestionName);
        var searchTerm = window.sessionStorage.getItem('searchTerm') ? window.sessionStorage.getItem('searchTerm') : null;
        var searchSuggest = window.sessionStorage.getItem('searchSuggest') ? window.sessionStorage.getItem('searchSuggest') : null;
        var userTypedSearchTerm = window.sessionStorage.getItem('userTypedSearchTerm') ? window.sessionStorage.getItem('userTypedSearchTerm') : null;
        var previousSearchTerm = window.sessionStorage.getItem('previousSearchTerm') ? window.sessionStorage.getItem('previousSearchTerm') : null;
        $('#clrfunction').val(suggestionName);
        window.location.href = url + `&searchterm=${searchTerm}&searchsuggest=${searchSuggest}&usertypedsearch=${userTypedSearchTerm}&previousSearchTerm=${previousSearchTerm}`;
    });

    $(document).on('click', '.srch_suggest .js-search_product-tile a', function (e) {
        e.preventDefault();
        var url = $(this).data('url');
        var lastSearchTerm = window.sessionStorage.getItem('lastSearchTerm');
        if (lastSearchTerm) {
            window.sessionStorage.setItem('previousSearchTerm', lastSearchTerm);
        } else {
            window.sessionStorage.removeItem('previousSearchTerm');
        }
        window.sessionStorage.setItem('lastSearchTerm', $('.search-field').val());
        var searchTerm = window.sessionStorage.getItem('searchTerm') ? window.sessionStorage.getItem('searchTerm') : null;
        var searchSuggest = window.sessionStorage.getItem('searchSuggest') ? window.sessionStorage.getItem('searchSuggest') : null;
        var userTypedSearchTerm = window.sessionStorage.getItem('userTypedSearchTerm') ? window.sessionStorage.getItem('userTypedSearchTerm') : null;
        var previousSearchTerm = window.sessionStorage.getItem('previousSearchTerm') ? window.sessionStorage.getItem('previousSearchTerm') : null;
        window.location.href = url + `&searchterm=${searchTerm}&searchsuggest=${searchSuggest}&usertypedsearch=${userTypedSearchTerm}&previousSearchTerm=${previousSearchTerm}`;
    });
};
