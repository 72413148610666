'use strict';
/* eslint-disable no-undef */
/* eslint-disable camelcase */

var tealiumEnabled = JSON.parse($('.enable_tealium').text());
var tealiumEnable = require('int_tealium_sfra/tealiumEnable');

/* MegaMenu: variables */
var mobileNavbtn = $('.js-navbar-toggler');
var mobNavclose = $('.js-close-nav');
var desktopNavclose = $('.js-close-desktop-nav');
var desksubNavbtn = $('.desktop-sub-cat');


/**
 * Returns true or false
 * @param {string} value - string value
 * @returns {boolean} - true or false
 */
function isEmpty(value) {
    return value === undefined || value === null || value === '' || value === 'undefined';
}

/**
 * Gets the screen size of the viewport
 * @returns {boolean} - true or false
 */
function getViewPortsize() {
    var screenSize = $(window).width();
    var isMobile;
    if (screenSize <= 768) {
        isMobile = true;
    } else if (screenSize > 768) {
        isMobile = false;
    }
    return isMobile;
}

/**
 * Triggers a tealium event tag and passes values based on given args.
 * @param {string} menuType - menu type to pass
 * @param {json} dataObj - data object
 */
function categoryNavEvent(menuType, dataObj) {
    if (tealiumEnabled) {
        var dataLayerAnalytics = {
            datalayerEvent: []
        };
        var l1; var l2; var l3; var name; var promoImage; var linkText; var creativeSpotImage;

        if (dataObj) {
            var linkName = menuType + ':';
            l1 = dataObj.l1; l2 = dataObj.l2; l3 = dataObj.l3; name = dataObj.name;
            promoImage = dataObj.promoImage; linkText = dataObj.linkText; creativeSpotImage = '';
            if (!isEmpty(l1)) {
                linkName += l1 + (!isEmpty(l2) ? ':' : '');
            }
            if (!isEmpty(l2)) {
                linkName += l2 + (!isEmpty(l3) ? ':' : '');
            }
            if (!isEmpty(l3)) {
                linkName += l3;
            }
            if (!isEmpty(promoImage)) {
                linkName += ':promo image';
                if (!isEmpty(l1) && !isEmpty(l2)) {
                    creativeSpotImage = 'nav-image-' + l1 + '-' + l2;
                }
            }
            if (!isEmpty(linkText)) {
                linkName += ':link text';
            }
            if (!isEmpty(l1) || !isEmpty(l2) || !isEmpty(l3)) {
                if (!isEmpty(promoImage)) {
                    dataLayerAnalytics.datalayerEvent.push({
                        event_name: 'nav_click',
                        nav_link_name: linkName,
                        nav_link_destination: dataObj.url,
                        creative_spot_image: creativeSpotImage
                    });
                } else if (!isEmpty(linkText)) {
                    dataLayerAnalytics.datalayerEvent.push({
                        event_name: 'nav_click',
                        nav_link_name: linkName,
                        nav_link_destination: dataObj.url,
                        creative_spot_text_link: linkText
                    });
                } else {
                    dataLayerAnalytics.datalayerEvent.push({
                        event_name: 'nav_click',
                        nav_link_name: linkName,
                        nav_link_destination: dataObj.url
                    });
                }
            } else if (!isEmpty(name)) {
                dataLayerAnalytics.datalayerEvent.push({
                    event_name: 'nav_click',
                    nav_link_name: linkName + name
                });
            }
            $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
        }
    }
}


function categoryLinkandText(targetElemet) {
    var dataObj;
    var navl1;
    var navl2;
    var linkName;

    if ($(targetElemet).hasClass('menu-level1')) {
        linkName = $(targetElemet).text().trim().toLowerCase() +':main nav link';
    } else if ($(targetElemet).closest('.l3-categories').length || $(targetElemet).closest('.featured-subcats').length) {
        dataObj = $(targetElemet).data('tealium-dataobject');
        navl1 = dataObj.l1;
        if (!isEmpty(dataObj.l2) && (dataObj.l2 != 'null')) {
            navl2 = ':' + dataObj.l2;
        } else {
            navl2 = '';
        }
        linkName = navl1 + navl2 + ':' + $(targetElemet).text().trim().toLowerCase();
    } else if ($(targetElemet).closest('.l1-shop').length || $(targetElemet).hasClass('level2-category') || $(targetElemet).hasClass('featured-category-heading')) {
        dataObj = $(targetElemet).data('tealium-dataobject');
        navl1 = dataObj.l1;
        linkName = navl1 + ':' + $(targetElemet).text().trim().toLowerCase();
    } else {
        linkName = $(targetElemet).text().trim().toLowerCase();
    }
    var destinationLink = $(targetElemet).attr('href').replace(/^\/(s\/(PVHTHUS|PVHCKCA|PVHCKUS|PVHTHCA)\/)?(en\/)?/, '/') + '/';
    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    dataLayerAnalytics.datalayerEvent.push({
        event_name: 'nav_click',
        nav_link_name: 'tn:'+ linkName,
        nav_link_destination: destinationLink
    });
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}
// eslint-disable-next-line no-constant-condition
if (tealiumEnabled) {
    if ($('.tealium-active').length > 0) {
        // If the tracking script is blocked by Adblock, create an empty object with the two used functions to prevent errors
        if (typeof utag === 'undefined') {
            window.utag = {
                link() {},
                view() {}
            };
        }

        const pushLinkNodes = function (dataAttributeName, nodePropertyName) {
            const linkNodes = document.querySelectorAll(dataAttributeName);
            if (linkNodes && linkNodes.dataset) {
                const utag_data_link = {};
                linkNodes.forEach(window.assignTracking.bind(this, nodePropertyName, utag_data_link));
                if (Object.keys(utag_data_link).length) {
                    utag.link(utag_data_link);
                }
            }
        };

        const eventNodes = document.querySelectorAll('[data-tealium-event]');
        if (eventNodes && eventNodes.dataset && eventNodes.dataset.tealiumEvent) {
            eventNodes.forEach(pushLinkNodes('[data-tealium-event]', 'tealiumEvent'));
        }

        window.$('body').on('search:sort:success', pushLinkNodes.bind(this, '[data-sort-event]', 'sortEvent'));
        window.$('body').on('search:applyFilter:success', pushLinkNodes.bind(this, '[data-filter-event]', 'filterEvent'));

        /**
         * Handle filter and sort order selection
         * @param {Object} utag UTAG Global object
         */
        $('body').on('datalayerEvent:sort-filter', function (e, utag) {
            const filterEventNode = document.querySelector('[data-filter-event]');
            const sortEventNode = document.querySelector('[data-sort-event]');

            if (filterEventNode && filterEventNode.dataset && filterEventNode.dataset.filterEvent !== '[]') {
                const filterEventData = JSON.parse(filterEventNode.dataset.filterEvent);
                if (filterEventData && filterEventData.constructor === Array) {
                    utag.link({
                        estore_event: filterEventData[0].estore_event,
                        page_items: filterEventData[0].page_items,
                        product_filter_options: filterEventData[0].product_filter_options
                    });
                }
            }

            if (sortEventNode && sortEventNode.dataset && sortEventNode.dataset.sortEvent !== '[]') {
                const sortEventData = JSON.parse(sortEventNode.dataset.sortEvent);
                if (sortEventData && sortEventData.constructor === Array) {
                    utag.link({
                        estore_event: sortEventData[0].estore_event,
                        page_sort: sortEventData[0].page_sort
                    });
                }
            }
        });

        /**
         * Handle Search Suggestion Data for Tealium
         * @param {string} searchMethod Search Method
         */
        $('body').on('datalayerEvent:search-suggestions', function (e, searchMethod) {
            var viewNode = document.querySelector('[data-suggestions-view]');
            if (viewNode && viewNode.dataset && viewNode.dataset.suggestionsView !== '[]') {
                var data = JSON.parse(viewNode.dataset.suggestionsView);
                if (data && utag) {
                    data.search_method = searchMethod;
                    window.utag_data = data;
                    if (data.length === 1) {
                        data = data[0];
                    }
                    utag.view(data);
                }
            }
        });
        /**
         * Handle link clicks, widget interactions, newsletter subscription submissions, etc.
         * @param {string} link clicks, widget interactions, newsletter subscription submissions, etc.
         */
        $('body').on('datalayerEvent:link', function (e, data) {
            var eventObj = data.datalayerEvent;
            var tealiumData = $('.datalayer-view-event').data('tealium-view');
            // Pass only the object if length is 1, else pass the complete object to dataLayer
            if (eventObj && eventObj.length === 1) {
                if (!!tealiumData && tealiumData.length > 0 && tealiumData[0].customer_email_address && !('customer_email_address' in eventObj[0])) {
                    var emailAddress = tealiumData[0].customer_email_address;
                    eventObj[0].customer_email_address = emailAddress;
                }
                eventObj = eventObj[0];
            }
            utag.link(eventObj);
        });
        /**
         * Handle modal popups, single page applications, tab views, etc.
         * @param {string} modal popups, single page applications, tab views, etc.
         */
        $('body').on('datalayerEvent:view', function (e, data) {
            var eventObj = data.datalayerEvent;
            // Pass only the object if length is 1, else pass the complete object to dataLayer
            if (eventObj && eventObj.length === 1) {
                eventObj = eventObj[0];
            }
            utag.view(eventObj);
        });

        /* MegaMenu: Adobe Analytics - Tealium */
        mobileNavbtn.on('click', function () {
            var menuType = 'tn';
            var dataObj = { name: 'open' };
            categoryNavEvent(menuType, dataObj);
        });

        mobNavclose.on('click', function () {
            var menuType = 'tn';
            var dataObj = { name: 'close' };
            categoryNavEvent(menuType, dataObj);
        });

        $('.js-desktopnav-overlay').on('click', '.track-tealium-magaMenu', function () {
            var menuType = 'tn';
            var dataObj = $(this).data('tealium-dataobject');
            categoryNavEvent(menuType, dataObj);
        });

        desktopNavclose.on('click', function () {
            var dataLayerAnalytics = {
                datalayerEvent: []
            };
            dataLayerAnalytics.datalayerEvent.push({
                event_name: 'nav_click',
                nav_link_name: 'tn:close'
            });
            $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
        });

        $('.js-left-catbtn').on('mouseover', function () {
            var that = $(this);
            var l3Section = $(this).closest('.categories-container').children('.js-right-subcat-one');
            var mappingID = $(this).closest('.level-one-cat').attr('id');
            setTimeout(function () {
                if ($(l3Section).is(':visible') && l3Section.hasClass(mappingID)) {
                    var menuType = 'tn';
                    var dataObj = that.data('tealium-dataobject');
                    categoryNavEvent(menuType, dataObj);
                }
            }, 500);
        });

        /* Mobile navigation functions*/
        $('body').on('click', '.js-target-cat', function () {
            var menuType = 'tn';
            var dataObj = { name: 'open' };
            categoryNavEvent(menuType, dataObj);
        });

        $('.js-mobile-menu-container').on('click', '.close-subcat-nav, .js-nav-page-head', function () {
            var menuType = 'tn';
            var dataObj = { name: 'close' };
            categoryNavEvent(menuType, dataObj);
        });

        /* Account show changes */
        $(document).on('click', function (e) {
            var menuType; var dataObj = {};
            if ($(e.target).parents('.js-acc-btn').length) {
                if (!$('.js-showpopover.acc-popover').hasClass('d-none-popover')) {
                    menuType = 'un';
                    dataObj = { name: 'account' };
                    categoryNavEvent(menuType, dataObj);
                }
            }
            if ($(e.target).parents('.global-footer').length) {
                var name = e.target.innerText.toLowerCase();
                menuType = 'ft';
                dataObj = { l1: name, url: e.target.pathname };
                categoryNavEvent(menuType, dataObj);
            }
        });

        desksubNavbtn.on('click', '.categories-container .right-cat-sec a', function () {
            var menuType = 'tn';
            var dataObj = $(this).data('tealium-dataobject');
            categoryNavEvent(menuType, dataObj);
        });

        $('.js-globalnav-header').on('click', '.navbar-header__brand a.logo-home', function () {
            var menuType = 'un';
            var dataObj = $(this).data('tealium-dataobject');
            categoryNavEvent(menuType, dataObj);
        });

        $('.js-globalnav-header').on('click', 'a.menu-level1', function () {
            var targetElemet = $(this);
            categoryLinkandText(targetElemet);
        });

        $(document).on('click', 'a.level2-category, a.l1-title-link, a.featured-category-heading', function () {
            var targetElemet = $(this);
            categoryLinkandText(targetElemet);
        });

        $(document).on('click', '.l3-categories a, .l3-categories a.ds-nav-link, .featured-subcats a.ds-nav-link', function () {
            var targetElemet = $(this);
            categoryLinkandText(targetElemet);
        });

        $(document).on('click', '.l1-shop a.ds-nav-link', function () {
            var targetElemet = $(this);
            categoryLinkandText(targetElemet);
        });

        // Top Promo banner
        $('body').on('click', '#headPromoWrapper', function (e) {
            var name;
            let currLink = e.target;
            var dataLayerAnalytics = {
                datalayerEvent: []
            };
            if($(currLink).children().length > 0 ) {
                name = $(currLink).clone().children().remove().end().text();
            } else {
                name = e.target.innerText.toLowerCase();
            }
            dataLayerAnalytics.datalayerEvent.push({
                event_name: 'site_interaction',
                site_interaction: 'globalnav:espot:' + name
            });
            $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
        });

        // Top Nav featured images
        $('body').on('click', '.js-featured-slot .feat-image', function (e) {
            var menuType = 'tn';
            var dataObj = $(this).closest('.category-asset').data('tealium-dataobject');
            dataObj.promoImage = e.target.getAttribute('src');
            dataObj.url = e.target.getAttribute('src');
            categoryNavEvent(menuType, dataObj);
        });
        // Top Nav featured Links
        $('body').on('click', '.js-featured-slot .feat-collect-link a', function (e) {
            e.preventDefault();
            var menuType = 'tn';
            var dataObj = $(this).closest('.category-asset').data('tealium-dataobject');
            dataObj.linkText = e.target.innerText.toLowerCase();
            dataObj.url = e.target.pathname;
            categoryNavEvent(menuType, dataObj);
            window.location.href = e.target.getAttribute('href');
        });

        /* MiniCart: Adobe Analytics - Tealium */
        $('.minicart').on('click', '.js-minicartdesk-link', function () {
            var isMob = getViewPortsize();
            var isCart = $('body').find('.cart-page').length > 0;
            if (!isMob && !isCart) {
                var dataLayerAnalytics = {
                    datalayerEvent: []
                };
                dataLayerAnalytics.datalayerEvent.push({
                    event_name: 'nav_click',
                    nav_link_name: 'un:shopping bag'
                });
                $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
            }
        });

        $('body').on('click', '.minicart-checkout a.checkout-btn', function () {
            var dataLayerAnalytics = {
                datalayerEvent: []
            };
            var dataObj = $(this).data('tealium-dataobject');
            dataLayerAnalytics.datalayerEvent.push({
                event_name: 'nav_click',
                nav_link_name: 'un:' + dataObj.name,
                nav_link_destination: dataObj.url
            });
            $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
        });

        /* Search: Adobe Analytics - Tealium */
        $(document).on('click', '.search .icon-search', function () {
            var dataLayerAnalytics = {
                datalayerEvent: []
            };
            dataLayerAnalytics.datalayerEvent.push({
                event_name: 'nav_click',
                nav_link_name: 'un:search'
            });
            $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
        });

        $(document).on('click', '.site-search .asset-close-icon ', function () {
            var dataLayerAnalytics = {
                datalayerEvent: []
            };
            dataLayerAnalytics.datalayerEvent.push({
                event_name: 'nav_click',
                nav_link_name: 'un:search:close'
            });
            $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
        });

        $(document).on('click', '#ctlMobileCtaButton', function () {
            tealiumEnable.getAnalyticsData('CTLMobileCTATracking');
        });

        // Tealium event for OOBO logged in flow
        setTimeout(() => {
            var isOOBO = $('#isOOBO').val();
            if (isOOBO && isOOBO === 'true') {
                if (!window.sessionStorage.getItem('ooboEventTriggered')) {
                    let dataLayerAnalytics = {
                        datalayerEvent: []
                    };
                    let ooboLogIn = {
                        event_name: 'csr log in oobo',
                        user_type: 'call center agent'
                    };
                    dataLayerAnalytics.datalayerEvent.push(ooboLogIn);
                    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
                    window.sessionStorage.setItem('ooboEventTriggered', true);
                }
            }
        }, 1000);
    }
}