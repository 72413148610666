'use strict';
var selectSizeOrColorData;

/**
 * Trigger add to cart tealium event
 * @param {Object} data - ajax add to cart response data
 * @param {Object} form - ajax add to cart input form data
 */
// eslint-disable-next-line
function addToCartEvent(data, form, cartAddMethod, setId, eventName) {
    if (data && form) {
        var dataLayerAnalytics = {
            datalayerEvent: []
        };
        var pdpData = $('.datalayer-view-event').data('tealium-view');
        pdpData = pdpData[0];
        var productItem = data.cart.items.find(item => item.id === form.pid);
        var price = productItem.analyticaPriceData;

        var productName = [];
        productName.push(productItem.productName);

        var productUpcId = [];
        productUpcId.push(form.pid);

        var productCartaddUnits = [];
        productCartaddUnits.push(form.quantity);

        var productPriceMsrp = [];
        productPriceMsrp.push(price.fullPrice.toString());

        var productPriceShortmark = [];
        productPriceShortmark.push(price.outOfDoorPrice.toString());

        var productPriceActual = [];
        productPriceActual.push(price.markdownPrice.toString());
        var addToCartData = {
            event_name: eventName,
            cart_id: data.cart.basketUUID,
            product_style_id: pdpData.product_style_id,
            cart_add_method: 'pdp',
            product_name: productName,
            product_upc_id: productUpcId,
            product_quantity: productCartaddUnits,
            cart_units_update: form.quantity,
            product_price_msrp: productPriceMsrp,
            product_price_shortmark: productPriceShortmark,
            product_price_actual: productPriceActual
        };
        if (setId) {
            addToCartData.product_bundle_style_id = [setId];
            addToCartData.cart_add_method = cartAddMethod;
        }
        if (cartAddMethod === 'pdp_fitfinder') {
            addToCartData.cart_add_method = cartAddMethod;
        }
        if (productItem.variationAttributes) {
            var colorcodeVariationAttr = productItem.variationAttributes.find(variationAttribute => variationAttribute.attributeId === 'colorCode');
            if (colorcodeVariationAttr) {
                var productUpcColorName = [];
                productUpcColorName.push(colorcodeVariationAttr.displayValue);
                var productUpcColorCode = [];
                productUpcColorCode.push(colorcodeVariationAttr.colorcode);
                addToCartData.product_upc_color_name = productUpcColorName;
                addToCartData.product_upc_color_code = productUpcColorCode;
            }
        }
        if (productItem.size) {
            var productUpcSize = [];
            productUpcSize.push(productItem.size);
            addToCartData.product_upc_size = productUpcSize;
        }
        addToCartData.product_dept = pdpData.product_dept;
        addToCartData.product_merch_category = pdpData.product_merch_category;
        addToCartData.product_master_category = pdpData.product_master_category;
        var tealiumData = $('.datalayer-view-event').data('tealium-view');
        if (!!tealiumData && tealiumData.length > 0) {
            addToCartData.A1_ID = 'A1_ID' in tealiumData[0] ? tealiumData[0].A1_ID : '';
        }
        dataLayerAnalytics.datalayerEvent.push(addToCartData);
        if (setId && data && data.cart && data.cart.cartAnalyticsData) {
            var cartData = data.cart.cartAnalyticsData;
            var index = cartData.product_upc_id ? cartData.product_upc_id.indexOf(form.pid) : null;
            if (index !== null && pdpData) {
                addToCartData.product_style_id = pdpData.product_style_id ? [pdpData.product_style_id[index]] : [];
                addToCartData.product_dept = pdpData.product_dept ? [pdpData.product_dept[index]] : [];
                addToCartData.product_merch_category = pdpData.product_merch_category ? [pdpData.product_merch_category[index]] : [];
                addToCartData.product_master_category = pdpData.product_master_category ? [pdpData.product_master_category[index]] : [];
            }
        }
        $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
    }
}

/**
 * Trigger change size/color tealium event
 * @param {Object} data - ajax change size/color response data
 * @param {string} attrId - selected variation attribute
 * @param {boolean} isViewAllColorPanel - true if color selected from view all colors panel
 */
function selectColorOrSizeEvent(data, attrId, isViewAllColorPanel) {
    if (data && attrId && attrId !== '-') {
        var dataLayerAnalytics = {
            datalayerEvent: []
        };
        var product = data.product;
        var price = product.analyticaPriceData;
        var eventName = 'product_change_size';
        if (attrId && attrId.toLocaleLowerCase() === 'colorcode') {
            eventName = 'product_change_color';
        }
        var productStyleId = [];
        productStyleId.push(product.masterProductData.ID ? product.masterProductData.ID : product.id);

        var productName = [];
        productName.push(product.productName);

        var productPriceMsrp = [];
        productPriceMsrp.push(price.fullPrice.toString());

        var productPriceShortmark = [];
        productPriceShortmark.push(price.outOfDoorPrice.toString());

        var productPriceActual = [];
        productPriceActual.push(price.markdownPrice ? price.markdownPrice.toString() : '0');
        var selectSizeData = {
            event_name: eventName,
            product_style_id: productStyleId,
            product_name: productName,
            product_price_msrp: productPriceMsrp,
            product_price_shortmark: productPriceShortmark,
            product_price_actual: productPriceActual
        };
        if (eventName === 'product_change_color') {
            selectSizeData.product_colorswatch_location = isViewAllColorPanel === true ? 'view all colors panel' : 'pdp color swatch';
            if (product.ATSvalue !== null && typeof product.ATSvalue !== 'undefined') {
                selectSizeData.product_stylecolorcode_id_inventory = [product.ATSvalue.toString()];
            }
        }
        if (product.allBadges !== null) {
            var productBadgeName = [];
            productBadgeName.push(product.allBadges);
            selectSizeData.product_badge_name = productBadgeName;
        }
        if (product.variationAttributes) {
            var colorcodeVariationAttr = product.variationAttributes.find(variationAttribute => variationAttribute.attributeId === 'colorCode');
            if (colorcodeVariationAttr) {
                var productUpcColorName = [];
                productUpcColorName.push(colorcodeVariationAttr.displayValue);
                var productUpcColorCode = [];
                productUpcColorCode.push(colorcodeVariationAttr.colorcode);
                selectSizeData.product_upc_color_name = productUpcColorName;
                selectSizeData.product_upc_color_code = productUpcColorCode;
            }
        }
        if (product.readyToOrder) {
            var productUpcSize = [];
            productUpcSize.push(product.size);
            selectSizeData.product_upc_size = productUpcSize;
            var productUpcId = [];
            productUpcId.push(product.id);
            selectSizeData.product_upc_id = productUpcId;
            var productUpcStockstatus = [];
            productUpcStockstatus.push(product.availability.messages[0]);
            selectSizeData.product_upc_stockstatus = productUpcStockstatus;
        }
        var pdpData = $('.datalayer-view-event').data('tealium-view');
        if (!!pdpData && pdpData.length > 0) {
            pdpData = pdpData[0];
            selectSizeData.product_dept = pdpData.product_dept;
            selectSizeData.product_merch_category = pdpData.product_merch_category;
            selectSizeData.product_master_category = pdpData.product_master_category;
            var colorAndSizeData = ['product_upc_color_code', 'product_upc_color_name', 'product_upc_size', 'product_upc_id', 'product_stylecolorcode_id_inventory', 'product_price_actual', 'product_price_msrp', 'product_price_shortmark', 'product_badge_name'];
            colorAndSizeData.forEach(key => {
                if (Object.prototype.hasOwnProperty.call(selectSizeData, key) && selectSizeData[key]) {
                    pdpData[key] = selectSizeData[key];
                } else if (Object.prototype.hasOwnProperty.call(pdpData, key) && pdpData[key]) {
                    delete pdpData[key];
                }
            });
            $('.datalayer-view-event').data('tealium-view', [pdpData]);
        }
        if ($('.product-set-detail').data('pid')) {
            var catData = product && product.categoryLevels ? product.categoryLevels : null;
            if (catData) {
                selectSizeData.product_dept = [catData.product_dept];
                selectSizeData.product_merch_category = [catData.product_merch_category];
                selectSizeData.product_master_category = [catData.product_merch_category];
            }
        }
        if (!!pdpData && pdpData.length > 0) {
            selectSizeData.A1_ID = 'A1_ID' in pdpData[0] ? pdpData[0].A1_ID : '';
        }
        selectSizeOrColorData = selectSizeData;
        dataLayerAnalytics.datalayerEvent.push(selectSizeData);
        $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
    }
}


/**
 * Trigger product image iteration tealium event
 * @param {boolean} tealiumImageClickEvent - check if event triggered already or not
 */
function productImageInteractionEvent(tealiumImageClickEvent) {
    if (tealiumImageClickEvent === false) {
        var dataLayerAnalytics = {
            datalayerEvent: []
        };
        var pdpData = selectSizeOrColorData;
        if (!selectSizeOrColorData) {
            pdpData = $('.datalayer-view-event').data('tealium-view');
            pdpData = pdpData[0];
        }
        var imageInteractionData = {
            event_name: 'product_image_interaction',
            product_style_id: pdpData.product_style_id,
            product_name: pdpData.product_name,
            product_price_msrp: pdpData.product_price_msrp,
            product_price_shortmark: pdpData.product_price_shortmark,
            product_price_actual: pdpData.product_price_actual
        };
        if (pdpData.product_badge_name) {
            imageInteractionData.product_badge_name = pdpData.product_badge_name;
        }
        if (pdpData.product_upc_color_code) {
            imageInteractionData.product_upc_color_code = pdpData.product_upc_color_code;
            imageInteractionData.product_upc_color_name = pdpData.product_upc_color_name;
        }
        if (pdpData.product_upc_size) {
            imageInteractionData.product_upc_size = pdpData.product_upc_size;
            imageInteractionData.product_upc_stockstatus = pdpData.product_upc_stockstatus;
            imageInteractionData.product_upc_id = pdpData.product_upc_id;
        }
        dataLayerAnalytics.datalayerEvent.push(imageInteractionData);
        $('body').trigger('datalayerEvent:view', dataLayerAnalytics);
    }
}

/**
 * Handles the zoom or thumbnail click event for product images.
 * @param {string} imageSrc - The ID of the clicked image for zoom or thumbnail interaction.
 * @param {string} eventType - The Type of event
 */
function handleProductImageEvent(imageSrc, eventType) {
    var eventName = eventType === 'zoomModal' ? 'product_image_zoom_modal_view' : 'product_image_zoom_interaction';
    if (!imageSrc) return;

    var dataLayerAnalytics = {
        datalayerEvent: []
    };

    var parts = imageSrc.split('/');
    var lastPart = parts[parts.length - 1];
    var imageId = lastPart.split('?')[0];
    var pdpData = selectSizeOrColorData;
    if (!selectSizeOrColorData) {
        pdpData = $('.datalayer-view-event').data('tealium-view');
        pdpData = pdpData[0];
    }

    var ImageZoomModalEventData = {
        event_name: eventName,
        product_style_id: pdpData.product_style_id,
        product_name: pdpData.product_name,
        product_upc_color_name: pdpData.product_upc_color_name,
        product_upc_color_code: pdpData.product_upc_color_code,
        product_image_id: [imageId]
    };

    // Push the zoom interaction event data to the data layer
    dataLayerAnalytics.datalayerEvent.push(ImageZoomModalEventData);
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}

/**
 * Product Details accordian expand capture
 * @param {string} productID - productID
 * @param {string} accordianName - accordianName
 */
// eslint-disable-next-line
function productFlyoutAnalytics(flyOutName) {
    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    var pdpData = $('.datalayer-view-event').data('tealium-view');
    pdpData = pdpData[0];
    var eventName = 'site_interaction';
    var siteInteraction = 'product:flyout:';
    // eslint-disable-next-line default-case
    switch (flyOutName) {
        case 'productDetailsModal':
            siteInteraction += 'product details';
            break;
        case 'shippingPanelModal':
            siteInteraction += 'shipping&returns';
            break;
        case 'reviewsModal':
            var hasReview = $('.tt-reviews-avgRating').length;
            if (hasReview) {
                siteInteraction += 'view reviews';
                eventName += ',product_view_ratingsreviews';
            } else {
                siteInteraction += 'click write a review';
                eventName += ',click_write_a_review';
            }
            break;
        // eslint-disable-next-line no-case-declarations
        case 'qaModal':
            let hasQnA = !$('.TTteaser__read-qa-panel').hasClass('TTteaser__read-no-qa');
            if (hasQnA) {
                siteInteraction += 'q&a’s';
            } else {
                siteInteraction += 'ask a question';
            }
            break;
        default :
            if (flyOutName === 'product_initiate_writereview') {
                siteInteraction += 'start write a review';
                eventName += ',' + flyOutName;
            } else {
                siteInteraction += 'reviews:view all reviews';
            }
    }
    dataLayerAnalytics.datalayerEvent.push({
        event_name: eventName,
        site_interaction: siteInteraction,
        product_style_id: pdpData.product_style_id
    });
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}


/**
 * Capturing interaction for 'View all colors'
 */
function showViewAllColorsTracking() {
    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    var trackingData = {
        event_name: 'site_interaction',
        site_interaction: 'product: link: view all colors panel'
    };
    var pdpData = $('.datalayer-view-event').data('tealium-view');
    if (!!pdpData && pdpData.length > 0) {
        pdpData = pdpData[0];
        trackingData.product_style_id = [pdpData.product_style_id ? pdpData.product_style_id.toString() : ''];
    }
    dataLayerAnalytics.datalayerEvent.push(trackingData);
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}
/**
 * Capturing interaction with FitAnalytics
 */
function fitAnalytics() {
    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    var fitAnalyticsdata = {
        event_name: 'product_click_fitfinder'
    };
    var pdpData = $('.datalayer-view-event').data('tealium-view');
    if (!!pdpData && pdpData.length > 0) {
        pdpData = pdpData[0];
        fitAnalyticsdata.product_style_id = pdpData.product_style_id;
    }
    dataLayerAnalytics.datalayerEvent.push(fitAnalyticsdata);
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}

/**
 * function to fetch and update tealium data using existing pdp variables
 * @param {Array} dataToUpdate - list of data to be updated
 * @returns {Object} - updated data
 */
function getDataFromTealium(dataToUpdate) {
    let pdpData = $('.datalayer-view-event').data('tealium-view');
    if (pdpData && pdpData.length > 0) {
        pdpData = pdpData[0];
        return dataToUpdate.reduce((acc, key) => {
            if (Object.prototype.hasOwnProperty.call(pdpData, key) && pdpData[key]) {
                // eslint-disable-next-line no-param-reassign
                acc[key] = pdpData[key];
            }
            return acc;
        }, {});
    }
    return {};
}
/**
 * Capturing the load of CTL Recommendation container
 * @param {string} recommendationType - Recommendation Type for Einstein or Custom Recommendations.
 */
function ctlContainerLoadEvent(recommendationType) {
    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    var dataToUpdate = ['product_badge_name', 'product_dept', 'product_master_category', 'product_merch_category', 'product_name',
        'product_price_actual', 'product_price_msrp', 'product_price_shortmark', 'product_style_id', 'product_stylecolorcode_id_inventory', 'product_upc_color_code', 'product_upc_color_name'
    ];
    var ctlContainerLoadData = {
        event_name: 'pdp_ctl_container_load',
        pdp_rec_type: recommendationType.toLowerCase() === 'einstein' ? 'ctl_einstein_look' : 'ctl_custom_look',
        ...getDataFromTealium(dataToUpdate)
    };
    dataLayerAnalytics.datalayerEvent.push(ctlContainerLoadData);
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}

/**
 * function to capture interaction with CTL Mobila CTA
 */
function CTLMobileCTATracking() {
    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    var dataToUpdate = ['product_master_category', 'product_merch_category', 'product_name', 'product_style_id',
        'product_upc_color_code', 'product_upc_color_name', 'product_upc_size', 'product_upc_id'
    ];
    var ctlMobileCTAData = {
        event_name: 'product_ctl_mobile_cta_click',
        ...getDataFromTealium(dataToUpdate)
    };
    dataLayerAnalytics.datalayerEvent.push(ctlMobileCTAData);
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}

/**
 * function to capture interaction with CTL Mobila CTA
 */
function ctlCarouselTracking(event_interaction) {
    var dataLayerAnalytics = {
        datalayerEvent: []
    };
    var pdpData = selectSizeOrColorData;
    if (!selectSizeOrColorData) {
        pdpData = $('.datalayer-view-event').data('tealium-view');
        pdpData = pdpData[0];
    }
    var CTLCarouselEventData = {
        event_name: event_interaction,
        product_style_id: pdpData.product_style_id,
        product_name: pdpData.product_name,
        product_merch_category: pdpData.product_merch_category
    };
    dataLayerAnalytics.datalayerEvent.push(CTLCarouselEventData);
    $('body').trigger('datalayerEvent:link', dataLayerAnalytics);
}

module.exports = {
    productFlyoutAnalytics: productFlyoutAnalytics,
    addToCartEvent: addToCartEvent,
    selectColorOrSizeEvent: selectColorOrSizeEvent,
    productImageInteractionEvent: productImageInteractionEvent,
    fitAnalytics: fitAnalytics,
    ctlContainerLoadEvent: ctlContainerLoadEvent,
    showViewAllColorsTracking: showViewAllColorsTracking,
    CTLMobileCTATracking: CTLMobileCTATracking,
    handleProductImageEvent: handleProductImageEvent,
    ctlCarouselTracking: ctlCarouselTracking
};


