'use strict';
let productTileTimeout = {};
let componentObserver;
var constants = require('base_global/constants');
var utils = require('../utils.js');
var colorSwatchConfig = JSON.parse($('input[name*="colorSwatchConfig"]').val());
var tealiumEnable = require('int_tealium_sfra/tealiumEnable');

/**
 * Gets the screen size of the viewport
 * @returns {boolean} - return if mobile true or false
 */
function isMobileDevice() {
    var screenSize = $(window).width();
    var isMobile;
    if (screenSize <= 768) {
        isMobile = true;
    } else if (screenSize > 768) {
        isMobile = false;
    }
    return isMobile;
}


/**
 * Set click on container
 * @param {jQuery} $container jQuery element
 */
function setSlickByTile($container) {
    if (!$container) return;
    setTimeout(() => {
        try {
            if ($container.hasClass('slick-initialized')) {
                $container.slick('unslick');
            }
            $container.slick({
                autoplay: false,
                speed: 500,
                dots: true,
                cssEase: 'ease',
                infinite: false,
                accessibility: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                lazyLoad: 'progressive',
                fade: true,
                prevArrow:
                    '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false" style=""><span class="icon-prev icon-chevron-left icon-image-slide"></span></button>',
                nextArrow:
                    '<button class="slick-next slick-arrow slick-disabled" aria-label="Next" type="button" aria-disabled="true" style=""><span class="icon-next icon-chevron-right icon-image-slide"></span></button>'
            });
        } catch (error) {
            // console.error('slider could not be initialized');
        }
    }, 0);
}

/**
 * Render product tile image
 * @param {Object} data image data
 * @returns {string} render image html
 */
function renderTileImage(data) {
    return `<div class="${data.className}">
        <a href="${data.productUrl}"
            class="pdpurl" data-searchcheck="${data.searchRedirect}">
            ${utils.renderPictureTag(data.image, {
                devices: {
                    desktop: data.image.imageDimentions.desktop.product,
                    mobile: data.image.imageDimentions.mobile.product
                },
                className: data.image.imageClassname,
                lazyload: data.image.lazyload
            })}
        </a>
    </div>`;
}

/**
 * get product image HTML
 * @param {Object} productAttr productAttribute
 * @returns {string} HTML to render
 */
function getProductImageHTML(productAttr) {
    if (!productAttr) return [];
    var available = productAttr.product.available;
    var isTeaser = productAttr.product.isTeaser;

    // check if product object exists
    if (!productAttr.product) {
        return '';
    }

    // To display single product image if item is not available
    if (!available && !isTeaser) {
        // eslint-disable-next-line no-param-reassign
        productAttr.product.images = [productAttr.product.images[0]];
    }
    // go through the list of images and create HTML markup from template
    let imageList = productAttr.product.images.map((image, index) => {
        const lazyloadClassName = index === 0 ? 'loaded' : '';

        return image && renderTileImage({
            className: index === 0 ? 'active' : '',
            productUrl: productAttr.product.url,
            searchRedirect: productAttr.searchRedirect,
            image: {
                ...image,
                lazyload: index !== 0,
                imageClassname: `plp-slick-img ${lazyloadClassName}`,
                url: image.url,
                imageDimentions: productAttr.product.imageDimentions
            }
        });
    });

    return imageList.join('');
}

/**
 * updates the product tile when a swatch attribute is selected
 * @param {string} html - response HTML
 * @param {jQuery} $tileContainer - tile container
 */
function handleResponse(html, $tileContainer) {
    var $html = $('<div>').append($.parseHTML(html));
    var $imageResponse;
    if (isMobileDevice()) {
        $imageResponse = $html.find('.plp-img-container__mob');
    } else {
        $imageResponse = $html.find('.plp-img-container__desk');
    }
    var $tileBodyResponse = $html.find('.tile-body');
    var $pdpLink = $tileBodyResponse.find('.pdp-link');
    var $productPrice = $tileBodyResponse.find('.price.ds-product-price');
    var $tileBodyFooter = $tileBodyResponse.find('.tile-body-footer');
    var $badgeResponse = $html.find('.badge-container');
    var $quickViewResponse = $html.find('.quickview');
    var $badgeResponseValue = $badgeResponse.data('badgename');
    var $tileBatchContainerDesk = $tileContainer.find('.badge-container');
    var $plpFinalSaleLabelResponse = $(html).find('.plp-final-sale-container');
    var $plpTileVariationPromotionContainer = $html.find('.promotions-container');
    $tileContainer.find('.pdp-link').html($pdpLink.html());
    $tileContainer.find('.price.ds-product-price').html($productPrice.html());
    $tileContainer.find('.tile-body-footer').html($tileBodyFooter.html());
    $tileContainer.find('.quickview').replaceWith($quickViewResponse);
    $tileBatchContainerDesk.removeClass('d-md-block');
    $tileBatchContainerDesk.html($badgeResponse.html());
    $tileContainer.find('.plp-final-sale-container').html($plpFinalSaleLabelResponse.html());
    $tileContainer.find('.promotions-container-wrapper').html($plpTileVariationPromotionContainer || '');

    if ($badgeResponseValue != null) {
        $tileBatchContainerDesk.attr('data-badgename', $badgeResponseValue);
        $tileBatchContainerDesk.addClass('d-md-block');
        $tileBatchContainerDesk.removeClass('d-none');
    } else {
        $tileBatchContainerDesk.attr('data-badgename', 'null');
        $tileBatchContainerDesk.addClass('d-none');
    }

    $tileContainer.find('.badge-container').html($badgeResponse.html());

    // enable quickview component after rendering the response
    setTimeout(() => PVH.mountAllComponents('quickView'), 500);

    try {
        // update product tile image on variation color selection
        let $imageContainer,
            productImageAttr,
            productImages;
        if (isMobileDevice()) {
            $imageContainer = $tileContainer.find('.image-container .plp-img-container__mob');
            productImageAttr = $imageResponse.data('productImageAttr') || [];
            productImages = getProductImageHTML(productImageAttr);
            if (productImages !== '') {
                $imageContainer.html(productImages);
            }
        } else {
            $imageContainer = $tileContainer.find('.image-container .plp-img-container__desk');
            productImageAttr = $imageResponse.data('productImageAttr') || [];
            productImages = getProductImageHTML(productImageAttr);
            if (productImages !== '') {
                $imageContainer.slick('unslick');
                $imageContainer.html(productImages);
                setSlickByTile($imageContainer);
            }
        }
        var productData = $html.find('.product-tile.ds-product-tile').data('product-data');
        tealiumEnable.getAnalyticsData('trackColorSwatchClick', productData);
    } catch (error) {
        // slick error no logging
    }
}

/**
 * get product rsponse when color swatch is selected
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $tileContainer - current tile container
 */
function attributeSelect(selectedValueUrl, $tileContainer) {
    if (selectedValueUrl) {
        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                handleResponse(data, $tileContainer);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * show images
 * @param {string} showHide - value to show or hide
 * @param {jQuery} $tileContainer - DOM container for a given product
 */
function updateImages(showHide, $tileContainer) {
    try {
        const $imageContainer = $tileContainer.find('.image-container .plp-img-container__desk');
        const slickObj = $imageContainer.slick('getSlick');

        if (showHide === 'show') {
            if (slickObj.slickCurrentSlide() === 0) {
                slickObj.slickGoTo(1, false);
            }
            return;
        }

        // go back to slide one when mouse leave only if user has not clicked around.
        if (showHide === 'hide' && !$imageContainer.data('manualSlide')) {
            setTimeout(() => {
                if (slickObj.slickCurrentSlide() === 1) {
                    slickObj.slickGoTo(0, false);
                }
            }, 500);
        }
    } catch (err) {
        // no logging
    }
}


/**
 * Disable existing observer
 * @param {Object} existingObservers Intersection observer instance
 */
function removeExistingObservers(existingObservers = componentObserver) {
    // disconnect existing observer to bind new (required to avoid duplicate binding)
    if (existingObservers) {
        existingObservers.disconnect();
    }
}

/**
 * First we get all the non-loaded image elements
 * @param {string} components -
 * @returns {IntersectionObserver} instance
 */
function initializeComponentOnView(components) {
    const componentList = document.querySelectorAll(components);
    removeExistingObservers();

    /** Then we set up a intersection observer watching over those images and whenever any of those becomes visible on the view then replace the placeholder image with actual one, remove the non-loaded class and then unobserve for that element **/
    componentObserver = new IntersectionObserver(function (entries) {
        var isPLPLoading = document.querySelectorAll('.product-grid')[0].classList.contains('loading');
        entries.forEach(function (entry) {
            if (entry.isIntersecting && !isPLPLoading) {
                let imageContainer = entry.target.querySelector('.plp-img-container');
                // render colorswatch component when new set of tiles are loaded
                setTimeout(() => window.PVH.mountAllComponents('Colorswatch'), 500);

                // GCT-11839 | Expose color swatches to provide an immediate indication of available colors.
                if (isMobileDevice() || colorSwatchConfig.exposeColorSwatch === true) {
                    // FIXME : this is buggy
                    const mobileImageContainer = entry.target.querySelector('.plp-img-container__mob');
                    if (mobileImageContainer) imageContainer = mobileImageContainer;
                }
                const productImageAttr = JSON.parse(imageContainer.dataset.productImageAttr) || [];
                const productImages = getProductImageHTML(productImageAttr);

                // do not update/inject html into container if no product image
                if (productImages !== '') {
                    imageContainer.innerHTML = productImages;
                }
                imageContainer.classList.add('loaded');
                componentObserver.unobserve(entry.target);

                // only trigger slider if desktop
                if (!isMobileDevice()) {
                    // delay to make sure slider is initalized in case of last scroll pos
                    setTimeout(() => {
                        setSlickByTile($(imageContainer));
                    }, 20);
                }
            }
        });
    });

    /** Now observe all the non-loaded images using the observer we have setup above **/
    componentList.forEach(function (lazyImage) {
        componentObserver.observe(lazyImage);
    });

    return componentObserver;
}

export default {
    init: function () {
        const componentSelector = '.product-grid .product-tile:not(.loaded)';
        initializeComponentOnView(componentSelector);

        // bind intersection observer when ever filter changes or PLP tile updates
        $(document).off('search:filterChange plpProductTile:update')
            .on('search:filterChange plpProductTile:update', () => {
                initializeComponentOnView(componentSelector);
            });
    },
    showColorSwatches: function () {

        $(document.body).on('mouseenter', '.product-tile .image-container', function (e) {
            e.preventDefault();
            const id = $(e.currentTarget).parents('.product').data('pid');

            if (!isMobileDevice()) {
                utils.lazyLoadImage($(this));

                productTileTimeout[id] = setTimeout(() => {
                    updateImages('show', $(this).parent());
                }, 300);
            }
        });

        $(document.body).on('touchstart', '.product-tile .image-container', function () {
            if (!isMobileDevice()) {
                utils.lazyLoadImage($(this));
            }
        });

        $(document.body).on('mouseleave', '.product-tile .image-container', function (e) {
            e.preventDefault();
            const id = $(e.currentTarget).parents('.product').data('pid');

            if (!isMobileDevice()) {
                clearTimeout(productTileTimeout[id]);
                updateImages('hide', $(this).parent());
            }
        });

        $(document.body).on('click', '.slick-arrow', function () {
            const $imageContainer = $(this).parents('.plp-img-container__desk');
            if ($imageContainer) {
                $imageContainer.data('manualSlide', true);
            }
        });
    },

    colorSwatchClick: function () {
        $(document.body).on('swatch:clicked', (e, selectedValueUrl, $tileContainer) => {
            attributeSelect(selectedValueUrl, $tileContainer);
        });
    }
};
