// eslint-disable-next-line
const CONSTANTS = {
    messages: {
        SERVER_ERROR: 'Something Went wrong !. Please try again.',
        INVALID_PHONE: 'Invalid phone',
        INVALID_ADDRESS_PHONENO: 'Please enter a valid phone number',
        INVALID_ZIP: 'Please enter a correct zip code',
        INVALID_NAME: 'Invalid name',
        INVALID_FIRSTNAME: 'First name is required and must be less than 20 characters',
        INVALID_LASTNAME: 'Last name is required and must be less than 20 characters',
        INVALID_CITYNAME: 'City name is required and must be less than 30 characters',
        INVALID_ORDER_NUMBER: 'Please enter a valid order number',
        INVALID_RANGE: 'Invalid range',
        INVALID_AMOUNT: 'Please enter a correct Amount',
        INVALID_EMAIL: 'Please enter a valid email address.',
        INVALID_ADDRESS: 'Please enter a valid address',
        INVALID_STATENAME: 'Please enter a valid state name.',
        INVALID_PASSWORD: 'Your password must contain the following:',
        INVALID_PHONENO: 'Please enter a valid phone number.',
        INVALID_GIFT_CHECK_BALANCE: 'There has been some error, please reach to the customer service team.',
        CONFIRM_TERMS: 'Please select the checkbox to confirm.',
        INVALID_PO_BOX: 'We cannot ship to P.O. Boxes. Please update your address.',
        INVALID_PURCHASE_AMOUNT: 'Purchase Amount value entered is non number',
        INVALID_BARCODE: 'The barcode number entered is invalid',
        MESSAGE_OTHER_SKU_INSTOCK: 'Select an available color and size or remove from your shopping bag.',
        MESSAGE_SOLD_OUT: 'Sold Out',
        MESSAGE_COLOR_OOS: 'Your previous selection is unavailable. Please select an available color and size below.',
        MESSAGE_SELECT_DAY: 'Please select a day',
        FINAL_SALE: 'FINAL SALE',
        FINAL_SALE_MESSAGE: 'Item cannot be returned.',
        components: {
            modal: {
                BTN_CLOSE: 'Close',
                TITLE_GIFT_CHECK_BALANCE: 'Shop with Gift Cards'
            }
        },
        OOS_status: 'Sold Out'
    },
    regex: {
        EMAIL: /^(?!.{61})\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.])*[a-zA-Z0-9]$/,
        NAME: /^[a-zA-Z0-9À-ÿ ’‘'.#\/\-]*$/,
        CITY: /^[a-zA-ZÀ-ÿ .#\/\-]{1,30}$/,
        ADDRESSLINE: /^[a-zA-Z0-9À-ÿ ’‘,'.#\/\-]*$/,
        NUMBERCHAR: /^[0-9*]*$/,
        STATE_NAME: /^[a-zA-Z0-9 .#/-]{1,35}$/,
        INTL_STATE_NAME: /^[a-zA-Z0-9 .#/-]{1,20}$/,
        SPECIALCHARVALIDATOR: /[;|\\{}`!$@&^%*]/g,
        PASSWORD_ALPHANUMERIC: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])([A-Za-z0-9\W_]){6,}$/,
        POSTAL: {
            US: /^\d{5}(-\d{4})?$/,
            CA: /^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]\d[abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ]( )\d[abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ]\d$/,
            REST: /^[a-zA-Z0-9 .#/-]*$/
        },
        PHONENO: {
            // eslint-disable-next-line
            DEFAULT: /^(\(\d{3})\)\s(\d{3})\-(\d{4})$/,
            // eslint-disable-next-line
            US: /^(\(\d{3})\)\s(\d{3})\-(\d{4})$/,
            // eslint-disable-next-line
            CA: /^(\(\d{3})\)\s(\d{3})\-(\d{4})$/,
            // eslint-disable-next-line
            FORMAT: /(\d{3}) (\d{3}) (\d{4})/,
            // eslint-disable-next-line
            MAXLENGTH: 15
        },
        AMOUNT: /(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)?(.[0-9]{1,2})?$/,
        PO_BOX: /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box)[-.\s]*)|.*((p |post)[-.\s]*(box)[-.\s]*)))).*$/,
        NUMERIC_ONLY_ALLOWED: /(?!\d+)./gmi,
        NUMERIC_AND_DECIMAL: /^[0-9]\d*(\.\d+)?$/,
        VALID_SHIP_PHONE_NUM: /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/
    },
    screen_size: {
        mobile: 'mobile',
        tablet: 'tablet',
        desktop: 'desktop',
        largeDesktop: 'large-desktop'
    },
    breakpoints: {
        lg: 992,
        md: 768,
        sm: 576,
        xl: 1280,
        xxl: 1400,
        xxxl: 1920
    },
    events: {
        CART_BEFORE_SHIPPING_METHOD_SELECTED: 'cart:beforeShippingMethodSelected',
        CART_BEFORE_UPDATE: 'cart:beforeUpdate',
        CART_ITEM_REMOVED: 'afterRemoveFromCart',
        CART_ITEM_SAVEDFORLATER: 'product:savedForLater',
        CART_ITEM_UPDATED: 'cart:itemUpdated',
        CART_SHIPPING_METHOD_SELECTED: 'cart:shippingMethodSelected',
        CART_UPDATED: 'cart:update',
        COUPON_REMOVED: 'removeCoupon:event',
        EDIT_PRODUCT_MODAL_READY: 'editproductmodal:ready',
        MEGAMENU_CLOSED: 'megamenu:closed',
        MEGAMENU_OPENED: 'megamenu:opened',
        NOTIFICATION_CLOSE: 'notification:close',
        ORDERHISTORY_SORT: 'orderHistory:sort',
        PRODUCT_ADDTOCART: 'product:afterAddToCart',
        PRODUCT_UPDATE_AVAILABILITY: 'product:updateAvailability',
        PROMOTION_BEFORE_UPDATE: 'promotion:beforeUpdate',
        PROMOTION_ERROR: 'promotion:error',
        PROMOTION_SUCCESS: 'promotion:success',
        SET_SHIPPING_METHOD_SELECTION: 'setShippingMethodSelection',
        WISHLIST_UPDATED: 'wishlist:update',
        SEARCH_FILTER_CHANGE: 'search:filterChange',
        PLP_PRODUCT_TILE_UPDATE: 'plpProductTile:update',
        NAV_UPDATED: 'nav:updated'
    },
    pageTypes: {
        ACCSUMMARY: 'accSummary',
        ORDERHISTORY: 'orderHistory',
        REWARDS: 'rewards',
        PROFILEINFO: 'profileInfo',
        ADDRESSES: 'addresses',
        WISHLIST: 'wishlist'
    },
    checkout: {
        SHIPPING: 'Shipping',
        PAYMENT: 'Payment'
    },
    image: {
        FALLBACK: 'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAFCAQAAADIpIVQAAAAEUlEQVR42mP89Z8BDBiJYAAA/CsJ44FW0NkAAAAASUVORK5CYII=',
        SUPPORTED_FORMATS: ['webp', 'pjpeg'],
        SUPPORTED_DEVICES: {
            DESKTOP: '(min-width:1400px)',
            MOBILE: ''
        },
        SUPPORTED_PIXEL_RATIO: ['1x', '1.25x']
    },
    imageDimensionKeys: {
        PLP: {},
        PDP: {}
    },
    TIMEOUT: {
        MENU_SHOW: 400,
        MENU_ITEM_HIDE: 700,
        MENU_ITEM_HIDE_PREP: 200,
        MENU_CLOSE: 600
    },
    NOTIFICATION_DEFAULT_TIMEOUT: 5000
};
module.exports = CONSTANTS;
