'use strict';
var base = require('./base');
var productGlobalVariable;
var slickClickFlag = false;
var tealiumEnable = require('int_tealium_sfra/tealiumEnable');
var quantityHelper = require('../quantitySelectorHelper');
var quickViewHelper = require('./quickViewHelper');
var quickViewHelperNew = require('../helpers/quickviewhelper');
var utils = require('../utils');
var constants = require('../constants');
var quickViewContainerId = '#quickViewModal';

/**
 * One Size product identification
 *
 * @param {string} sizeVariation - to find out product is one size or not
*  @return {boolean} - boolean for one size product identification.
 */
function isOneSizeProduct(sizeVariation) {
    return sizeVariation.id.toLowerCase() === 'one size' || sizeVariation.id.toLowerCase() === 'onesize';
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }

    var htmlString =
        '<!-- Modal -->' +
        '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">' +
        '<span class="enter-message sr-only" ></span>' +
        '<div class="modal-dialog choose-bonus-product-dialog" ' +
        'data-total-qty="' +
        data.maxBonusItems +
        '"' +
        'data-UUID="' +
        data.uuid +
        '"' +
        'data-pliUUID="' +
        data.pliUUID +
        '"' +
        'data-addToCartUrl="' +
        data.addToCartUrl +
        '"' +
        'data-pageStart="0"' +
        'data-pageSize="' +
        data.pageSize +
        '"' +
        'data-moreURL="' +
        data.showProductsUrlRuleBased +
        '"' +
        'data-bonusChoiceRuleBased="' +
        data.bonusChoiceRuleBased +
        '">' +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        '    <span class="">' +
        data.labels.selectprods +
        '</span>' +
        '    <button type="button" class="close" data-dismiss="modal">' +
        '        <span aria-hidden="true">&times;</span>' +
        '        <span class="sr-only"> </span>' +
        '    </button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '<div class="modal-footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the 'Add to Cart' button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    if (!$('#quickViewModal.show .quickview-set').length) {
        // Skip modal close for Product set pages
        $('#quickViewModal .modal-close button.close').trigger('click');
    }
    $('.minicart').trigger('count:update', response);
    utils.updateCartCount(response.quantityTotal);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (
        response.newBonusDiscountLineItem &&
        Object.keys(response.newBonusDiscountLineItem).length !== 0
    ) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append('<div class="add-to-cart-messages"></div>');
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' +
            messageType +
            ' add-to-basket-alert text-center" role="alert">' +
            response.message +
            '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

/**
 * @param {string} attr selecrts colour
 * @param {boolean} strikeThroughStatus return strike through status value
 */
function processNonSwatchValues(attr, strikeThroughStatus, quickViewContainerId) {
    attr.values.forEach(function (attrValue, index) {
        var $attrValue = $(quickViewContainerId + ' #' + attr.id + 'item' + index);
        const attributeId = attr.id + 'item' + index;
        var isSelectableLabel = $(`${quickViewContainerId} #${attributeId}`);
        $attrValue.attr('data-url', attrValue.url).removeAttr('disabled');
        if (!attrValue.selectable) {
            $attrValue.attr('disabled', '');
            if (!strikeThroughStatus) {
                $(isSelectableLabel).css('display', 'none');
            }
        } else {
            $(isSelectableLabel).css('display', 'flex');
        }
        if (attrValue.selected) {
            $(quickViewContainerId + ' label.' + attr.id).html(
                '<span class="variation__attr--name">' + attr.displayName + '</span><span class="variation__attr--value">' + attr.displayValue + '</span>'
            );
            $attrValue.attr('aria-checked', 'true');
        } else {
            $attrValue.attr('aria-checked', 'false');
        }
    });
}
/**
 * @param {string} attrs selects color
 * @param {boolean} strikeThroughStatus return strike through status value
 * @param {srting} quickViewContainerId defines the boundary around quickview modal
 */
function updateAttrs(attrs, strikeThroughStatus, quickViewContainerId) {
    var isOneSize = isOneSizeProduct(attrs[1].values[0]);
    attrs.forEach(function (attr) {
        if (attr.swatchable) {
            quickViewHelper.processSwatchValues(attr, strikeThroughStatus, quickViewContainerId);
        } else {
            processNonSwatchValues(attr, strikeThroughStatus, quickViewContainerId);
        }
        if (isOneSize) {
            if (!attr.swatchable && attr.displayValue === '') {
                $(quickViewContainerId + ' label.' + attr.id).html(
                    '<span class="variation__attr--name">' + attr.displayName + '</span><span class="variation__attr--value">' + attr.values[0].displayValue + '</span>'
                );
            }
        } else if (!attr.swatchable && attr.displayValue === '') {
            $(`${quickViewContainerId} label.${attr.attributeId}`).html(`<span class="variation__attr--name">${attr.displayName}</span>`);
        }
    });
}

/**
 * @param {String} message - error message
 */
function handleDataError(message) {
    var $pageQuantityError = $(quickViewContainerId + ' .page-quantity-error');
    $pageQuantityError.html(utils.showErrorMessage(message));
    $pageQuantityError[0].scrollIntoView({ behavior: 'smooth' });
}

/**
 * @param {Object} product - response object
 */
function updateImages(product, quickViewContainerId) {
    var imgResponse = '';
    var imgObj = {};
    if (product.imageDimentions.desktop.product) {
        imgObj.desktop = product.imageDimentions.desktop.product;
        imgObj.mobile = product.imageDimentions.mobile.product;
    } else {
        imgObj.desktop = product.imageDimentions.desktop.quickBuy.product;
        imgObj.mobile = product.imageDimentions.desktop.quickBuy.product;
    }
    product.images.large.forEach((item, index) => {
        imgResponse += '<div class="' + (index === 0 ? 'active' : '') + '">' +
            utils.renderPictureTag(item, { devices: { desktop: imgObj.desktop, mobile: imgObj.mobile }, className: 'quickview-slick-img' }) +
            '</div>';
    });
    if ($(quickViewContainerId + ' .js-quickview-slick').hasClass('slick-initialized')) {
        $(quickViewContainerId + ' .js-quickview-slick').slick('removeSlide', null, null, true);
    } else {
        $(quickViewContainerId + ' .js-quickview-slick').html('');
    }
    $(quickViewContainerId + ' .js-quickview-slick').slick('slickAdd', imgResponse);
    // update slide count
    if ($('.js-quickview-slick').slick('getSlick') && $('.js-quickview-slick').slick('getSlick').slideCount !== undefined && $('.js-quickview-slick').slick('getSlick').slideCount !== null) {
        $('.slide-count').text('1/' + $('.js-quickview-slick').slick('getSlick').slideCount); // Initialize slide count
    }
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 */
function handleVariantResponse(response, quickViewContainerId) {
    if (response.product.variationAttributes) {
        updateAttrs(
            response.product.variationAttributes,
            response.product.strikeThroughStatus,
            quickViewContainerId
        );
    }
    $(quickViewContainerId + ' .quickview__modal__head--name').html(
        '<a class="quickview__modal-heading-link" href=' + response.product.selectedProductUrl + '>' + response.product.productName + '</a>'
    );
    // Update Promotions
    $(quickViewContainerId + ' .quickview__modal__body--inner').find('.product-promotions').empty().html(response.product.promotionsHtml);
    updateImages(response.product, quickViewContainerId);
    quickViewHelper.updateProductURL(response.product, $('#quickViewModal'));
    $(quickViewContainerId + ' .quickview__modal__body--prices').empty().html(response.product.price.html);
    if (!response.product.orderable) {
        $(quickViewContainerId + ' .sales .value').html(constants.messages.OOS_status);
    }
    $(
        quickViewContainerId + ' button.add-to-cart,' + quickViewContainerId + ' button.add-to-cart-global,' + quickViewContainerId + ' button.update-cart-product-global'
    )
        .trigger('product:updateAddToCartQuickView', {
            product: response.product
        })
        .trigger('product:statusUpdate', response.product);
}

/**
 * Update Badges
 *
 * @param {Object} responseData - Data response from AJAX call
 */
function updateBadges(responseData, quickViewContainerId) {
    var isOneSize = isOneSizeProduct(responseData.variationAttributes[1].values[0]);
    var availabilityValue = '';
    var preOrderStatus = '';
    var availabilityMessages = responseData.availability.messages;
    var isAvailability = responseData.available;
    var imgbadges = '';
    var stockBadges = '';
    var saleBadges = '';
    var availabilityMessageExists = false;
    var responseMessage = responseData.badgeSkuLevelStatus.preOrderMessage;
    var responseMessageDate = responseData.badgeSkuLevelStatus.preOrderMessageDate;
    if (responseData.badgeSkuLevelStatus.preOrderStatus) {
        preOrderStatus += '<div class="pre-order"> <span>' + responseMessage + '</span> <span class="pre-order-date">' + responseMessageDate + '</span> </div>';
    }
    availabilityMessages.forEach(function (message) {
        if (responseData.badgeSkuLevelStatus.availabilityStatus) {
            availabilityValue += `<li><div class="${isAvailability ? '' : 'custom-error'}">${message}</div></li>`;
        }
    });

    availabilityMessageExists = availabilityValue && (responseData.readyToOrder || isOneSize);

    if (responseData.badgeSkuLevelStatus.newBadge) {
        imgbadges += responseData.badgeSkuLevelStatus.newBadge;
        imgbadges += responseData.badgeSkuLevelStatus.imageBadge1 || responseData.badgeSkuLevelStatus.imageBadge2 ? ' , ' : '';
    }

    if (responseData.ImagebadgeSkuLevelStatus.imageBadge1) {
        imgbadges += responseData.ImagebadgeSkuLevelStatus.imageBadge1;
    } else if (responseData.ImagebadgeSkuLevelStatus.imageBadge2) {
        imgbadges += responseData.ImagebadgeSkuLevelStatus.imageBadge2;
    } else if (responseData.ImagebadgeSkuLevelStatus.imageBadge3) {
        imgbadges += responseData.ImagebadgeSkuLevelStatus.imageBadge3;
    }

    if (!responseData.badgeSkuLevelStatus.availabilityStatus && responseData.availability.inStockValue.length === 0) {
        stockBadges += '';
    } else if (responseData.availability.inStockValue.length !== 0) {
        stockBadges += `<div class="product-detail__salebadges-text inventory-msg"> ${responseData.availability.inStockValue} </div>`;
    }

    if (responseData.badgeSkuLevelStatus.finalSalestatus) {
        saleBadges += '<div class="custom-error product-detail__salebadges-text quickview__modal__body--finalBadge">Final Sale</div>';
    }
    if (responseData.readyToOrder || isOneSize) {
        $(quickViewContainerId + ' .availability-msg').removeClass('error').empty().html(availabilityValue);
    } else {
        $(quickViewContainerId + ' .availability-msg').removeClass('error').empty().html('');
    }
    $(quickViewContainerId + ' .preorder-status').empty().html(preOrderStatus);
    $(quickViewContainerId + ' .product-detail__imgbadges').empty().html(imgbadges);
    $(quickViewContainerId + ' .product-detail__stockBadges').empty().html(stockBadges);
    $(quickViewContainerId + ' .product-detail__salebadges').empty().html(saleBadges);
}

/**
 *
function to handle previous error response from server
 * @param {string} attribute_id -
 *
 */
function handlePreviousErrorResponse(attribute_id, quickViewContainerId) {
    $(quickViewContainerId + ' .' + attribute_id + ' span.variation__attr--name').removeClass('modal-error-color');
    $(quickViewContainerId + ' li[role="radio"].variant-' + attribute_id + ' span').removeClass('modal-error-color');
    $($(quickViewContainerId + ' .' + attribute_id).parents('#pdpAttribute')).find('div.variant-selector-container .variant-item').removeClass('modal-error-border');
    $(quickViewContainerId + ' .variant-error-' + attribute_id).removeClass('show').addClass('hide');
}

/**
 * @param {string} selectedValueUrl selects size
 * @param {jQuery} productContainer - DOM element for current product
 * @param {string} eventName event name on variation select
 * @param {string} attrId - attribute ID of product
 */
function attributeSelect(selectedValueUrl, productContainer, eventName, attrId, quickViewContainerId) {
    // $productContainer
    if (selectedValueUrl) {
        var $quickviewModalContent = $('.quickview__modal--content');
        var $defaultImage = $(quickViewContainerId + ' .primary-images > #defaultImage');
        if ($defaultImage) $defaultImage.hide();
        $.ajax({
            url: selectedValueUrl + '&showQuickView=true',
            method: 'GET',
            success: function (data) {
                var isOneSize = isOneSizeProduct(data.product.variationAttributes[1].values[0]);
                var quickViewModalPos = $quickviewModalContent.scrollTop();
                var addToBagButtonText = $(quickViewContainerId + ' .add-to-cart-hidden').val();
                var productData = data.product;
                var productId = productContainer.data('pid');
                var buttonHtmlSelectSize = $(quickViewContainerId + ' .select-size-hidden').val();
                productGlobalVariable = data.product;
                var buttonSalesPrice = data.product.isAddToBagPrice && data.product.price.salesDisplayPrice ? ' - ' + data.product.price.salesDisplayPrice : '';
                if (buttonSalesPrice === '') {
                    buttonSalesPrice = data.product.isAddToBagPrice && data.product.price.showSavings ? ' - ' + data.product.price.salesDisplayRange : '';
                }
                $('.page-quantity-error').empty();
                handleVariantResponse(data, quickViewContainerId);
                quickViewHelperNew.default.updateBadges(data.product, quickViewContainerId);
                quantityHelper.updateQuantities(data.product, productContainer, productId, quickViewContainerId);
                handlePreviousErrorResponse(attrId, quickViewContainerId);
                $('.error-text').remove();
                if (isOneSize && data.product.oneSizeSKUID) {
                    $(quickViewContainerId + ' .add-to-cart-global').attr('data-cur-pid', data.product.oneSizeSKUID);
                } else {
                    $(quickViewContainerId + ' .add-to-cart-global').attr('data-cur-pid', data.product.id);
                }
                $('.modal.show .full-pdp-link').attr('href', data.product.selectedProductUrl);
                if (!data.product.readyToOrder && !isOneSize) {
                    $('button.add-to-cart-global').empty().html(buttonHtmlSelectSize);
                } else {
                    if (productData.badgeSkuLevelStatus.preOrderStatus) {
                        $('button.add-to-cart-global').empty().html(productData.badgeSkuLevelStatus.preOrderBadge + buttonSalesPrice);
                    } else {
                        $('button.add-to-cart-global').empty().html(addToBagButtonText + buttonSalesPrice);
                    }
                }
                // null check to disable/enable addToBag CTA
                utils.disableButton($(quickViewContainerId + ' button.add-to-cart-global'), data.product.price.salesDisplayPrice);
                var $primaryImages = $(quickViewContainerId + ' .primary-images');
                if ($primaryImages) {
                    if ($primaryImages.outerHeight() === 0) {
                        $(quickViewContainerId + ' .primary-images > #defaultImage').show();
                    } else {
                        $(quickViewContainerId + ' .primary-images > #defaultImage').hide();
                    }
                }
                $quickviewModalContent.scrollTop(quickViewModalPos);
                if ($('.product-set-detail').length) {
                    $('body').trigger('productSet:quickview');
                }
                if (eventName !== '') {
                    tealiumEnable.getAnalyticsData('getVariationSelectedData', productData, eventName, 'pdp');
                }
            },
            error: function () {
                $quickviewModalContent.spinner().stop();
            }
        });
    }
}

/**
 * Handles the errors for the variation categories
 */
function handleVariantErrorMessage() {
    var $variantError;
    $('#quickViewModal .variant-list, #editProductModal .variant-list, #editProductModalMob .quickbuy--variant-list').each(function () {
        var $this = $(this);
        if ($this.hasClass('colors-variant-list')) {
            return;
        }
        var mode_of_variant = $this.data('display-id');
        if ($this.has('.variant-item[aria-checked="true"], .variant-color-item[aria-checked="true"]').length) {
            $('li[role="radio"].variant-' + mode_of_variant).removeClass('modal-error-border');
            $this.closest('#pdpAttribute').find('.variant-error').removeClass('show');
            $this.closest('#pdpAttribute').find('.variant-error').addClass('hide');
        } else {
            $variantError = $this.closest('#pdpAttribute').find('.variant-error');
            $variantError.removeClass('hide').addClass('show');
            $('#quickViewModal, #editProductModal, #editProductModalMobfind').find('.' + mode_of_variant + ' span.variation__attr--name').addClass('modal-error-color');
            $('li[role="radio"].variant-' + mode_of_variant).addClass('modal-error-border');
        }
    });

    if ($variantError && $variantError.length > 0) {
        var isProductSet = !!$('body').has('div.product-set-detail').length;
        var scrollOptions = { behavior: 'smooth', block: 'nearest' };

        // set scroll pos to default in product set quick view
        if (isProductSet) {
            scrollOptions.block = 'start';
        }

        $variantError[0].scrollIntoView(scrollOptions);
    }
}

/**
 * function to handle validation UI on Quantity Selector
 * @param {string} mode_of_variant - when user clicks on the add to bag button without selecting size then gives the value as size in string
 */
function updateVariantOnQuantitySelectorStatus(mode_of_variant, mode_of_variant_value, quickViewContainerId) {
    if ($(quickViewContainerId + ' .' + mode_of_variant + ' span.variation__attr--name').html()) {
        const isVariantLabelAvailable = $(quickViewContainerId + ' .' + mode_of_variant + ' span.variation__attr--name').html().replace(/\s+/g, ' ').trim();
        if (isVariantLabelAvailable.toLowerCase() === 'select ' + mode_of_variant_value.toLowerCase() + '') {
            $(quickViewContainerId + ' .' + mode_of_variant + ' span.variation__attr--name').addClass('modal-error-color');
            $($(quickViewContainerId + ' .' + mode_of_variant).parents('#pdpAttribute')).find('div.variant-selector-container .variant-item').addClass('modal-error-border');
            $(quickViewContainerId + ' li[role="radio"].variant-' + mode_of_variant + ' span').addClass('modal-error-border');
            $(quickViewContainerId + ' .variant-error-' + mode_of_variant).removeClass('hide');
            $(quickViewContainerId + ' .variant-error-' + mode_of_variant).addClass('show').trigger('focus');
            $('.quickview__modal--content').animate({
                scrollTop: $('.' + mode_of_variant).parents('#pdpAttribute').find('.variant-error').offset().top
            }, 500);
        }
    }
}

module.exports = {
    ...base,
    quickViewContainerId,
    showQuickview: function () {
        $(document).on('keyup', '.product-recommendation__tile-wrapper .ds-product-tile', function (e) {
            if (e.code === 'Enter') {
                $(this).find('.quickview').trigger('click');
            }
        });
    },

    addToCart: function () {
        $(document).on(
            'click',
            `${quickViewContainerId} button.add-to-cart, ${quickViewContainerId} button.add-to-cart-global`,
            function () {
                var recommendationType = $('body').has('div[data-recomtype]').length ? $('body div[data-recomtype]').data('recomtype') : '';
                var isProductSet = !!$('body').has('div.product-set-detail').length;
                var pid = $(this).attr('data-cur-pid');
                if (pid === undefined) {
                    pid = $(this).attr('data-pid');
                }
                var qty = $(quickViewContainerId + ' .quantity-dropdown option:selected').val();
                var addToCartUrl = $(quickViewContainerId + ' .add-to-cart-url').val();
                var readyToOrder = $(quickViewContainerId + ' .add-to-cart-global').attr('data-readytoorder');
                var pageType = $('.page.current').data('page');
                $('.error-text').remove();
                var csrf_token = $('.csrf-token').attr('data-csrf-value');
                var form = {
                    pid: pid,
                    quantity: qty,
                    pageType: pageType,
                    csrf_token: csrf_token
                };
                handleVariantErrorMessage();
                if (addToCartUrl && readyToOrder === 'true') {
                    $.ajax({
                        url: addToCartUrl,
                        method: 'POST',
                        data: form,
                        success: function (data) {
                            if (data.error) {
                                handleDataError(data.message);
                            } else {
                                $('.page-quantity-error').empty();
                                handlePostCartAdd(data);
                                $('body').trigger('product:afterAddToCart', data);
                                productGlobalVariable = data.cart.items.find(item => item.id === form.pid);
                                tealiumEnable.getAnalyticsData('getAddToCartData', productGlobalVariable, data.cart.basketUUID, '', isProductSet ? 'bundle' : recommendationType);
                                $.spinner().stop();
                            }
                        },
                        error: function (data) {
                            let message = data.responseJSON.message;
                            let errorHandling = function () {
                                $.spinner().stop();
                                $('.error-text').remove();
                                $('.quickview__modal__footer').prepend(`<span class="error-text page-error d-none"><svg class="icon-utility-alert-red"><use xlink:href="#alert-red"></use></svg>${message}</span>`);
                                $('#quickViewModal .error-text').removeClass('d-none').trigger('focus');
                                tealiumEnable.getAnalyticsData('serverError', data.responseJSON.errorCode);
                            };
                            if (data.responseJSON.csrfError) {
                                utils.handleCsrfToken(errorHandling);
                            } else {
                                errorHandling();
                            }
                        }
                    });
                }
            }
        );
    },

    colorAttribute: function () {
        $(document).on(
            'click',
            `${quickViewContainerId} .variant-color-item:not([disabled])`, function (e) {
                e.preventDefault();
                var $productContainer = $(quickViewContainerId + ' .product-detail');
                var selectedValueUrl = $(this).attr('data-url');
                var eventName = 'product_change_color';
                var selectedAttrId = $(this).attr('data-display-id');
                attributeSelect(selectedValueUrl, $productContainer, eventName, selectedAttrId, quickViewContainerId);
                $('.quickview__modal--content').animate({
                    scrollTop: $('body').offset().top
                }, 500);
            }
        );

        $(document).on('keyup', `${quickViewContainerId} .variant-color-item:not([disabled])`, function (event) {
            if (event.code === 'Enter') {
                $(this).trigger('click');
            }
        });
    },

    selectAttribute: function () {
        $(document).on('click', `${quickViewContainerId} .variant-item:not([disabled])`, function (e) {
            e.preventDefault();
            var $productContainer = $(quickViewContainerId + ' .product-detail');
            var selectedValueUrl = $(this).attr('data-url');
            var eventName = 'product_change_size';
            var selectedAttrId = $(this).attr('data-display-id');
            attributeSelect(selectedValueUrl, $productContainer, eventName, selectedAttrId, quickViewContainerId);
        });

        $(document).on('keyup', `${quickViewContainerId} .variant-item:not([disabled])`, function (event) {
            if (event.code === 'Enter') {
                $(this).trigger('click');
            }
        });
    },

    quantitySelect: function () {
        $(document).on('change', `${quickViewContainerId} .quantity-dropdown select`, function (e) {
            e.preventDefault();
            var $productContainer = $(quickViewContainerId + ' .product-detail');
            var selectedValueUrl = $('option:selected', $(this)).data('url');
            if (quantityHelper.isProductSetavailable()) {
                $productContainer = $(this).closest('.set-item.productSet-wrapper');
            }
            var eventName = '';
            attributeSelect(selectedValueUrl, $productContainer, eventName, '-', quickViewContainerId);
        });
    },

    updateAddToCart: function () {
        $('body').on('product:updateAddToCartQuickView', function (e, response) {
            // update local add to cart (for sets)
            e.preventDefault();
            e.stopPropagation();
            var isOneSize = isOneSizeProduct(response.product.variationAttributes[1].values[0]);
            $(quickViewContainerId + ' .add-to-cart-global').attr('data-readytoorder', (response.product.readyToOrder || isOneSize));
        });
    },

    quickViewSlickAnalytics: function () {
        $(document).on('click', '.js-quickview-slick .slick-arrow', function () {
            if (!slickClickFlag) {
                var eventName = 'product_image_interaction';
                tealiumEnable.getAnalyticsData('getVariationSelectedData', productGlobalVariable, eventName, 'pdp');
                slickClickFlag = true;
            }
        });
        $(document).on('hidden.bs.modal', '#quickViewModal', function () {
            slickClickFlag = false;
        });
    },

    quickViewQuantityErrorWarning: function () {
        $(document).on('click', `${quickViewContainerId} .js-quantitySelector`, function (e) {
            let containerData = e.target.nodeName !== 'SELECT' ? $(e.target).siblings('select') : e.target;
            if ($(containerData).attr('disabled')) {
                const variants = $('#quickViewModal').find('.variant-list:not([data-display-id="colorCode"])').toArray();
                variants.forEach(elem => {
                    const variant = $(elem)[0].dataset.displayId;
                    const variantValue = $(elem)[0].dataset.displayValue;
                    let variantItem = $(quickViewContainerId + ' section.attributes').find('label.' + variant);
                    if (variantItem.length) {
                        updateVariantOnQuantitySelectorStatus(variant, variantValue, quickViewContainerId);
                    }
                });
            }
        });
    },
    handleVariantErrorMessage
};
