'use strict';
var tealiumEnabled = JSON.parse($('.enable_tealium').text());
var quickViewAnalytics = require('int_tealium_sfra/quickViewAnalytics');
var productListAnalytics = require('int_tealium_sfra/productListAnalytics');
var productDetailAnalytics = require('int_tealium_sfra/productDetailAnalytics');
var minicartAnalytics = require('int_tealium_sfra/minicartAnalytics');
var checkoutShippingAnalytics = require('./checkout/shippingAnalytics');
var checkout = require('./checkout/checkoutAnalytics');
var cart = require('./cartAnalytics');
var billingAnalytics = require('./checkout/billingAnalytics');
var createAccountAnalytics = require('./account/createAccountAnalytics');
var orderConfirmAnalytics = require('./checkout/orderConfirmAnalytics');
var breadcrumbAnalytics = require('./pageBreadcrumb');
var contextFunction;
module.exports = {
    getAnalyticsData() {
        if (tealiumEnabled) {
            var args = [].slice.call(arguments);
            var context = args.shift();
            // eslint-disable-next-line default-case
            switch (context) {
                case 'updateFilterPanelEvent':
                case 'applyFilterEvent':
                case 'sortEvent':
                case 'showFiltersEvent':
                case 'navToPDPFromPLPAnalytics':
                case 'trackColorSwatchClick':
                    contextFunction = productListAnalytics[context];
                    contextFunction.apply(context, args);
                    break;
                case 'getVariationSelectedData':
                case 'getAddToCartData':
                case 'quickViewEvent':
                    contextFunction = quickViewAnalytics[context];
                    contextFunction.apply(context, args);
                    break;
                case 'productImageInteractionEvent':
                case 'handleProductImageEvent':
                case 'selectColorOrSizeEvent':
                case 'addToCartEvent':
                case 'productFlyoutAnalytics':
                case 'fitAnalytics':
                case 'ctlContainerLoadEvent':
                case 'ctlCarouselTracking':
                case 'showViewAllColorsTracking':
                case 'CTLMobileCTATracking':
                    contextFunction = productDetailAnalytics[context];
                    contextFunction.apply(context, args);
                    break;
                case 'miniCartAddOrSubtractEvent':
                case 'miniCartRemoveAnalytics':
                    contextFunction = minicartAnalytics[context];
                    contextFunction.apply(context, args);
                    break;
                case 'checkoutAddressSuggestEvent':
                case 'checkoutEditLineItemEvent' :
                case 'checkoutRemoveLineItemEvent' :
                case 'checkoutAddnewAddressEvent' :
                    contextFunction = checkoutShippingAnalytics[context];
                    contextFunction.apply(context, args);
                    break;
                case 'placeOrderEvent' :
                case 'reviewPageLoadEvent' :
                case 'payByLinkEvent' :
                    contextFunction = checkout[context];
                    contextFunction.apply(context, args);
                    break;
                case 'editProduct' :
                case 'applyCoupon' :
                case 'addGiftBox' :
                case 'initiateRegularCheckout' :
                case 'saveForLaterEvent' :
                case 'moveToBagEvent' :
                //cart error
                case 'serverError':
                    contextFunction = cart[context];
                    contextFunction.apply(context, args);
                    break;
                case 'creatAccountAndLoginEvent':
                case 'emailOptInTracking':
                case 'forgetPasswordCTA':
                case 'checkEmailCTA':
                    contextFunction = createAccountAnalytics[context];
                    contextFunction.apply(context, args);
                    break;
                case 'checkoutAddressPaymentSuggestEvent':
                case 'onSubmitPayment':
                case 'onBillingPageLoad':
                case 'giftCardSuccess':
                    contextFunction = billingAnalytics[context];
                    contextFunction.apply(context, args);
                    break;
                case 'editOrderOnLoad':
                case 'CancelOrderOnLoad':
                case 'accountRegisterOnload':
                case 'EditOrderSuccess':
                case 'CancelOrderSuccess':
                case 'accountRegisterSuccess':
                    contextFunction = orderConfirmAnalytics[context];
                    contextFunction.apply(context, args);
                    break;
                case 'breadcrumbAnalytics':
                    contextFunction = breadcrumbAnalytics[context];
                    contextFunction.apply(context, args);
                    break;
            }
        }
    }
};