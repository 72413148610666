/*
* Helper functions for quickview
*/

/**
 * Update product URL based on current selected product
 * @param {Object} product Product Object
 * @param {jQuery} $container jQuery selector
 */
function updateProductURL(product, $container) {
    var viewDetailsLinkLabel = $('.viewdetails-link-label').val();
    if ($container) {
        $container.find('.viewdetails-link').html(
            `<a class="ds-btn-block secondary" target="_self" href="${product.pdpurl ? product.pdpurl : product.selectedProductUrl}"> ${viewDetailsLinkLabel} </a>`
        );
    }
}

/**
 * Process attribute values in QBB associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 */
function renderSwatchValuesQBB(attr ,isGrouped) {
    const values = isGrouped ? attr.values.flatMap(group => group.values) : attr.values;

    values.forEach((attrValue, index) => {
        const $quickAttrValue = $(`.${attr.id}quick-${attrValue.value}`);
        $quickAttrValue.attr('aria-checked', attrValue.selected ? 'true' : 'false');
        $quickAttrValue.closest('li').toggleClass('colorOOS', !attrValue.selectable);
        updateAttributeDisplay($quickAttrValue, attrValue, attr);
    });
}

/**
 * @param {string} attr selects colour
 * @param {boolean} strikeThroughStatus return strike through status value
 * @param {jQuery} $quickViewContainerId - DOM element for quick view container 
 * @param {boolean} isTeaser true if product is a teaser product
 */
function updateAttributeDisplay($element, attrValue, attr, quickViewContainerId) {
    const isSelected = attrValue.selected;
    quickViewContainerId = quickViewContainerId ? quickViewContainerId : '';

    $element.attr('aria-checked', isSelected ? 'true' : 'false');
    $element.attr('data-url', attrValue.url || '');

    if (isSelected) {
        $(quickViewContainerId + ' label.' + attr.id).html(
            `<span class="variation__attr--name">${attr.displayName}</span><span class="variation__attr--value">${attr.displayValue}</span>`
        );
    }
}

function processAttributeValues(attr, quickViewContainerId, isGrouped, strikeThroughStatus, isTeaser) {
    const baseSelector = quickViewContainerId ? `${quickViewContainerId} #` : '#';
    const values = isGrouped ? attr.values.flatMap(group => group.values) : attr.values;

    values.forEach((attrValue, index) => {
        const attrId = `${attr.id}item-${attrValue.value}`;
        const $attrValue = $(`${baseSelector}${attrId}`);

        updateAttributeDisplay($attrValue, attrValue, attr, quickViewContainerId);

        $attrValue.toggleClass('colorOOS', !attrValue.selectable);
        quickViewContainerId = quickViewContainerId ? quickViewContainerId : '';
         if (!$attrValue.length && attrValue.selectable) {
            let $floatingBucket = $(quickViewContainerId + ' .js-floatingColorList');
            if ($floatingBucket.length) {
                var swatchValues = `<li id="${attr.id}item-${attrValue.value}"
                                    class='variant-list__item variant-color-item variant-${attr.id}' role='radio' aria-labelledby="color_S_Label"
                                    data-attr-value="${attrValue.value}" data-display-id='${attr.id}' data-url="${attrValue.url}"
                                    tabindex="0" style="background-image: url(${attrValue.images.swatch.length !== 0 ? attrValue.images.swatch[0].url : ''})">
                                </li>`;
                $floatingBucket.removeClass('d-none').append(swatchValues);
            }
        }
    });
}

function processSwatchValues(attr, strikeThroughStatus, quickViewContainerId, isTeaser = false) {
    const isGrouped = attr.isGroupedByPrice;
    quickViewContainerId = quickViewContainerId ? quickViewContainerId : '';
    if ($(`${quickViewContainerId} .quickbuy--variant-selector-container`).length) {
        renderSwatchValuesQBB(attr, isGrouped);
    } else {
        processAttributeValues(attr, quickViewContainerId, isGrouped, strikeThroughStatus, isTeaser);
    }

    var $colorLists = $('.variation-spacing-colorCode .variant-list');
    $colorLists.each(function () {
        let $elem = $(this);
        let $visibleColorSwatch = $elem.find('.variant-list__item:not(.colorOOS)');
        if (!$visibleColorSwatch.length) {
            $elem.addClass('d-none');
            $elem.prev().addClass('d-none');
        } else {
            $elem.removeClass('d-none');
            $elem.prev().removeClass('d-none');
        }
    });
}


module.exports = {
    updateProductURL : updateProductURL,
    processSwatchValues: processSwatchValues
};
